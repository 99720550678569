/*!
    Miri UI Kit Free V1.0.0
    Developed by BootstrapDash(https://www.bootstrapdash.com/)
*/

body {
    font-size: 13px;
}

.bg-gradient-black {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e4e5e7), to(#252631));
    background-image: linear-gradient(#e4e5e7, #252631);
}

.fixed-on-scroll.fixed-on-top .nav-link.icon-fb:hover {
    color: #444bf8;
}

.fixed-on-scroll.fixed-on-top .nav-link.icon-twitter:hover {
    color: #2dd2f6;
}

.fixed-on-scroll.fixed-on-top .nav-link.icon-insta:hover {
    color: #f12459;
}

.navbar-dark .navbar-nav .nav-link.icon-fb:hover {
    color: #444bf8;
}

.navbar-dark .navbar-nav .nav-link.icon-twitter:hover {
    color: #2dd2f6;
}

.navbar-dark .navbar-nav .nav-link.icon-insta:hover {
    color: #f12459;
}

.icon-fb:hover {
    color: #444bf8;
}

.icon-twitter:hover {
    color: #2dd2f6;
}

.icon-insta:hover {
    color: #f12459;
}

.icon-gmail:hover {
    color: #f12459;
}

.content-wrapper {
    padding-top: 73px;
    padding-bottom: 76px;
}

.card-demo-wrapper {
    padding: 30px 25px;
}

.card-demo-wrapper .navbar {
    z-index: 0;
}

.demo-color-box {
    display: inline-block;
    width: 130px;
    height: 96px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.solbrismaritime-dash-front .btn,
.solbrismaritime-dash-front .badge {
    margin-right: 5px;
    margin-bottom: 8px;
}

.solbrismaritime-dash-front .progress {
    margin-top: 40px;
}

.card-title {
    margin-bottom: 26px;
}

.pb-30 {
    padding-bottom: 30px;
}

.example-section h2 {
    padding-top: 76px;
    padding-bottom: 69px;
}

.example-section figcaption {
    padding-top: 33px;
    color: #ffffff;
}

.example-section .img-thumbnail {
    border-color: #ffffff;
}

.solbrismaritime-dash-section {
    padding-top: 55px;
    padding-bottom: 55px;
}

@media screen and (min-width: 992px) {
    .feature-box {
        max-width: 280px;
    }
}

.how-we-work-section .btn-rounded {
    font-size: 20px;
    padding: 6px 9px;
}

.btn-label {
    font-size: 12px;
}

.experience-section {
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 1px solid #e3e3e3;
}

.preview-link {
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.preview-link::before {
    content: "\F349";
    font-family: "Material design icons";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    font-size: 2.5rem;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.preview-link:hover::before {
    opacity: 1;
}

@keyframes ldio-4i25i67fsok-1 {
    0% {
        top: 29.700000000000003px;
        height: 237.60000000000002px
    }

    50% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }

    100% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }
}

@keyframes ldio-4i25i67fsok-2 {
    0% {
        top: 44.550000000000004px;
        height: 207.9px
    }

    50% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }

    100% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }
}

@keyframes ldio-4i25i67fsok-3 {
    0% {
        top: 59.400000000000006px;
        height: 178.20000000000002px
    }

    50% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }

    100% {
        top: 89.10000000000001px;
        height: 118.80000000000001px
    }
}

.ldio-4i25i67fsok div {
    position: absolute;
    width: 41.580000000000005px
}

.ldio-4i25i67fsok div:nth-child(1) {
    left: 53.46px;
    background: #c1c1c1;
    animation: ldio-4i25i67fsok-1 1.6393442622950818s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.32786885245901637s
}

.ldio-4i25i67fsok div:nth-child(2) {
    left: 127.71000000000001px;
    background: #c1c1c1;
    animation: ldio-4i25i67fsok-2 1.6393442622950818s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.16393442622950818s
}

.ldio-4i25i67fsok div:nth-child(3) {
    left: 201.96px;
    background: #c1c1c1;
    animation: ldio-4i25i67fsok-3 1.6393442622950818s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: undefineds
}

.loadingio-spinner-pulse-7ytjweudsrn {
    width: 297px;
    height: 297px;
    overflow: hidden;
    background: none;
}

.ldio-4i25i67fsok {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-4i25i67fsok div {
    box-sizing: content-box;
}

.browser-screen-loading-content {
    text-align: center;
    height: 2em;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 3em;
    left: 0;
    margin: auto;
}

.loading-dots {
    margin-left: -1.5em;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: loading-dots-fadein .5s linear forwards;
    -moz-animation: loading-dots-fadein .5s linear forwards;
    -o-animation: loading-dots-fadein .5s linear forwards;
    -ms-animation: loading-dots-fadein .5s linear forwards;
    animation: loading-dots-fadein .5s linear forwards;
}

.loading-dots i {
    width: .5em;
    height: .5em;
    display: inline-block;
    vertical-align: middle;
    background: #e0e0e0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 .125em;
    -webkit-animation: loading-dots-middle-dots .5s linear infinite;
    -moz-animation: loading-dots-middle-dots .5s linear infinite;
    -o-animation: loading-dots-middle-dots .5s linear infinite;
    -ms-animation: loading-dots-middle-dots .5s linear infinite;
    animation: loading-dots-middle-dots .5s linear infinite;
}

.loading-dots.dark-gray i {
    background: #707070;
}

.loading-dots i:first-child {
    -webkit-animation: loading-dots-first-dot .5s infinite;
    -moz-animation: loading-dots-first-dot .5s linear infinite;
    -o-animation: loading-dots-first-dot .5s linear infinite;
    -ms-animation: loading-dots-first-dot .5s linear infinite;
    animation: loading-dots-first-dot .5s linear infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translate(-1em);
    -moz-transform: translate(-1em);
    -o-transform: translate(-1em);
    -ms-transform: translate(-1em);
    transform: translate(-1em);
}

.loading-dots i:last-child {
    -webkit-animation: loading-dots-last-dot .5s linear infinite;
    -moz-animation: loading-dots-last-dot .5s linear infinite;
    -o-animation: loading-dots-last-dot .5s linear infinite;
    -ms-animation: loading-dots-last-dot .5s linear infinite;
    animation: loading-dots-last-dot .5s linear infinite;
}


@-moz-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-moz-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}


@-moz-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-webkit-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-o-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}


@-moz-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-webkit-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-o-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}