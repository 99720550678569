@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-Regular.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-Italic.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-Medium.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-Medium.woff) format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-MediumItalic.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-Bold.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-Bold.woff) format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: MiVUText;
  src: url(/static/font/mivu/MiVUText-BoldItalic.woff2) format("woff2"),
    url(/static/font/mivu/MiVUText-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #444bf8;
  --secondary: #6c757d;
  --success: #00c9a6;
  --info: #2dd2f6;
  --warning: #f1be24;
  --danger: #f12459;
  --light: #e4e5e7;
  --dark: #252631;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

* {
  font-family: MiVUText, Helvetica Neue, Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: Flow-Block;
  src: url("/static/font/flow-block.woff") format("woff");
  font-display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2c2c;
  text-align: left;
  background-color: #ffffff;
  overflow: overlay;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}


abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #444bf8;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0911e7;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #bbbbbb;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;

  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2c2c2c;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  /* border-top: 1px solid #e7eaf3; */
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e7eaf3;
}

.table tbody+tbody {
  border-top: 2px solid #e7eaf3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e7eaf3;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e7eaf3;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2c2c2c;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #cbcdfd;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #9ea1fb;
}

.table-hover .table-primary:hover {
  background-color: #b2b5fc;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #b2b5fc;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #b8f0e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #a3ecdf;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #c4f2fc;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #92e8fa;
}

.table-hover .table-info:hover {
  background-color: #acedfb;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #acedfb;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #fbedc2;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #f8dd8d;
}

.table-hover .table-warning:hover {
  background-color: #f9e6aa;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #f9e6aa;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fbc2d1;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #f88da9;
}

.table-hover .table-danger:hover {
  background-color: #f9aabf;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f9aabf;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #f7f8f8;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #f1f1f3;
}

.table-hover .table-light:hover {
  background-color: #e9ecec;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #e9ecec;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c2c2c5;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #8e8e94;
}

.table-hover .table-dark:hover {
  background-color: #b5b5b9;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b5b5b9;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e7eaf3;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #bbbbbb;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e7eaf3;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #bbbbbb;
  background-color: #ffffff;
  border-color: #bfc1fd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #bbbbbb;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #2c2c2c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
  color: #bbbbbb;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00c9a6;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 201, 166, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #00c9a6;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.375rem);
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #00c9a6;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #00c9a6;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 2rem / calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #00c9a6;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.25);
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #00c9a6;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #00c9a6;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  border-color: #00c9a6;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #00fcd0;
  background-color: #00fcd0;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #00c9a6;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #00c9a6;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #00c9a6;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f12459;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(241, 36, 89, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f12459;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f12459' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f12459' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.375rem);
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #f12459;
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f12459;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f12459' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f12459' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #ffffff no-repeat center right 2rem / calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f12459;
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.25);
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #f12459;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #f12459;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #f12459;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #f4547d;
  background-color: #f4547d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #f12459;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #f12459;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: #f12459;
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  color: #2c2c2c;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #1f28f7;
  border-color: #131cf6;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 102, 249, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #131cf6;
  border-color: #0912f3;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 102, 249, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #00a386;
  border-color: #00967c;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 179, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00967c;
  border-color: #008971;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 179, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-info:hover {
  color: #ffffff;
  background-color: #0ac9f2;
  border-color: #0abfe6;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 184, 215, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #0abfe6;
  border-color: #09b5da;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 184, 215, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0ac0e;
  border-color: #d4a30e;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 167, 37, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d4a30e;
  border-color: #c89a0d;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 167, 37, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #e00e45;
  border-color: #d40e41;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 69, 114, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #d40e41;
  border-color: #c80d3d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 69, 114, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-light:hover {
  color: #212529;
  background-color: #d0d2d5;
  border-color: #c9cbcf;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 200, 203, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #c9cbcf;
  border-color: #c2c5c9;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 200, 203, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #15151b;
  border-color: #0f0f14;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 71, 80, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #0f0f14;
  border-color: #0a0a0d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 71, 80, 0.5);
}

.btn-outline-primary {
  color: #444bf8;
  border-color: #444bf8;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #444bf8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #00c9a6;
  border-color: #00c9a6;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00c9a6;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-outline-info {
  color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #2dd2f6;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-outline-warning {
  color: #f1be24;
  border-color: #f1be24;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f1be24;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-outline-danger {
  color: #f12459;
  border-color: #f12459;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f12459;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-outline-light {
  color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #e4e5e7;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-outline-dark {
  color: #252631;
  border-color: #252631;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #252631;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #444bf8;
  text-decoration: none;
}

.btn-link:hover {
  color: #0911e7;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2c2c2c;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #444bf8;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #bbbbbb;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #e7eaf3;
  border-radius: 2px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 2rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #ffffff;
  border-color: #444bf8;
  background-color: #444bf8;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #bfc1fd;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #ffffff;
  background-color: #f0f1fe;
  border-color: #f0f1fe;
}

.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #444bf8;
  background-color: #444bf8;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(68, 75, 248, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(68, 75, 248, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(68, 75, 248, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(68, 75, 248, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #bbbbbb;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #ffffff;
  border: 1px solid #e7eaf3;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #bfc1fd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-select:focus::-ms-value {
  color: #bbbbbb;
  background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 50px;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #bfc1fd;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 50px;
  padding: 0.75rem 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #bbbbbb;
  background-color: #ffffff;
  border: 1px solid #e7eaf3;
  border-radius: 2px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.5;
  color: #bbbbbb;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 2px 2px 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #444bf8;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f0f1fe;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #444bf8;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #f0f1fe;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #444bf8;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #f0f1fe;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e7eaf3;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #e7eaf3;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #444bf8;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar>.container,
.navbar>.container-fluid {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 16px;
  padding-left: 16px;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff;
}

.navbar-dark .navbar-text a {
  color: #ffffff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }

  .card-group>.card {
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion>.card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card .card-header {
  margin-bottom: -1px;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 3px !important;
}

.breadcrumb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 3rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent !important;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px -5px rgb(0 0 0 / 20%);
  margin-top: 4rem;
  width: fit-content;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #444bf8;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0911e7;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 6px 12px;
  font-size: 0.8125rem;
  height: 25px;
  font-weight: 600;
  line-height: 1;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #444bf8;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #ffffff;
  background-color: #131cf6;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #00c9a6;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #ffffff;
  background-color: #00967c;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #2dd2f6;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #0abfe6;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f1be244b;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d4a30e;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #f12459;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #ffffff;
  background-color: #d40e41;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #e4e5e7;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #c9cbcf;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #252631;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #ffffff;
  background-color: #0f0f14;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #232781;
  background-color: #444bf8;
  border-color: #cbcdfd;
}

.alert-primary hr {
  border-top-color: #b2b5fc;
}

.alert-primary .alert-link {
  color: #181b59;
}

.alert-secondary {
  color: #383d41;
  background-color: #6c757d;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #006956;
  background-color: #00c9a6;
  border-color: #b8f0e6;
}

.alert-success hr {
  border-top-color: #a3ecdf;
}

.alert-success .alert-link {
  color: #00362c;
}

.alert-info {
  color: #176d80;
  background-color: #2dd2f6;
  border-color: #c4f2fc;
}

.alert-info hr {
  border-top-color: #acedfb;
}

.alert-info .alert-link {
  color: #0f4855;
}

.alert-warning {
  color: #7d6313;
  background-color: #f1be24;
  border-color: #fbedc2;
}

.alert-warning hr {
  border-top-color: #f9e6aa;
}

.alert-warning .alert-link {
  color: #51400c;
}

.alert-danger {
  color: #7d132e;
  background-color: #f12459;
  border-color: #fbc2d1;
}

.alert-danger hr {
  border-top-color: #f9aabf;
}

.alert-danger .alert-link {
  color: #510c1e;
}

.alert-error {
  color: #7d132e;
  background-color: #f12459;
  border-color: #fbc2d1;
}

.alert-error hr {
  border-top-color: #f9aabf;
}

.alert-error .alert-link {
  color: #510c1e;
}

.alert-light {
  color: #777778;
  background-color: #e4e5e7;
  border-color: #f7f8f8;
}

.alert-light hr {
  border-top-color: #e9ecec;
}

.alert-light .alert-link {
  color: #5e5e5e;
}

.alert-dark {
  color: #131419;
  background-color: #252631;
  border-color: #c2c2c5;
}

.alert-dark hr {
  border-top-color: #b5b5b9;
}

.alert-dark .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 8px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 8px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  position: absolute !important;
  display: flex;
  min-width: 0px !important;
  height: 5px;
  padding: 0 !important;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #2e3152ab !important;
  transition: all .75s ease;
  /* border-radius: 4px; */
  left: 0;
  top: 0;
}

.progress-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #444bf8;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 8px 8px;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #2c2c2c;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #232781;
  background-color: #cbcdfd;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #232781;
  background-color: #b2b5fc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #232781;
  border-color: #232781;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #006956;
  background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #006956;
  background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #006956;
  border-color: #006956;
}

.list-group-item-info {
  color: #176d80;
  background-color: #c4f2fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #176d80;
  background-color: #acedfb;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #176d80;
  border-color: #176d80;
}

.list-group-item-warning {
  color: #7d6313;
  background-color: #fbedc2;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #7d6313;
  background-color: #f9e6aa;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #7d6313;
  border-color: #7d6313;
}

.list-group-item-danger {
  color: #7d132e;
  background-color: #fbc2d1;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7d132e;
  background-color: #f9aabf;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7d132e;
  border-color: #7d132e;
}

.list-group-item-light {
  color: #777778;
  background-color: #f7f8f8;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #777778;
  background-color: #e9ecec;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #777778;
  border-color: #777778;
}

.list-group-item-dark {
  color: #131419;
  background-color: #c2c2c5;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #131419;
  background-color: #b5b5b9;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #131419;
  border-color: #131419;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}

.close:hover {
  color: #000000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e7eaf3;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e7eaf3;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer> :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2c2c2c;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #444bf8 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #131cf6 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #00c9a6 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00967c !important;
}

.bg-info {
  background-color: #2dd2f6 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0abfe6 !important;
}

.bg-warning {
  background-color: #f1be24 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d4a30e !important;
}

.bg-danger {
  background-color: #f12459 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d40e41 !important;
}

.bg-light {
  background-color: #e4e5e7 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c9cbcf !important;
}

.bg-dark {
  background-color: #252631 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0f0f14 !important;
}

.bg-white,
.fixed-on-scroll.fixed-on-top {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e7eaf3 !important;
}

.border-top {
  border-top: 1px solid #e7eaf3 !important;
}

.border-right {
  border-right: 1px solid #e7eaf3 !important;
}

.border-bottom {
  border-bottom: 1px solid #e7eaf3 !important;
}

.border-bottom-2px {
  border-bottom: 2px solid #e7eaf3 !important;
}

.border-bottom-2px-dashed {
  border-bottom: 2px dashed #e7eaf3 !important;
}

.border-left {
  border-left: 1px solid #e7eaf3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #444bf8 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #00c9a6 !important;
}

.border-info {
  border-color: #2dd2f6 !important;
}

.border-warning {
  border-color: #f1be24 !important;
}

.border-danger {
  border-color: #f12459 !important;
}

.border-light {
  border-color: #e4e5e7 !important;
}

.border-dark {
  border-color: #252631 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top,
.fixed-on-scroll.fixed-on-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #444bf8 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0911e7 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #00c9a6 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #007d67 !important;
}

.text-info {
  color: #2dd2f6 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #09aace !important;
}

.text-warning {
  color: #f1be24 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #bc910c !important;
}

.text-danger {
  color: #f12459 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bc0c3a !important;
}

.text-light {
  color: #e4e5e7 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #bcbec3 !important;
}

.text-dark {
  color: #252631 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #040405 !important;
}

.text-body {
  color: #2c2c2c !important;
}

.text-muted {
  color: #bbbbbb !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {

  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " ("attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: #e7eaf3;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e7eaf3;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 1.5;
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 1.5;
}

h3,
.h3 {
  font-size: 1.75rem;
  line-height: 1.5;
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.5;
}

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.5;
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.5;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  color: #000;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  line-height: 1.2;
}

p {
  font-weight: 400;
  line-height: 27px;
}

blockquote,
.blockquote {
  font-size: 1rem;
  font-weight: 300;
}

blockquote p,
.blockquote p {
  margin-bottom: 0.5rem;
  line-height: 1.63;
}

blockquote footer,
blockquote .blockquote-footer,
.blockquote footer,
.blockquote .blockquote-footer {
  font-size: 0.8125rem;
  color: #000000;
  font-weight: 500;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.lead {
  font-size: 1rem;
}

.img-raised {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.27);
}

.img-rounded {
  border-radius: 50rem;
}

.text-black {
  color: #000000;
}

.raise-on-hover {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.raise-on-hover:hover {
  -webkit-transform: scale(1.05) translateY(-10px);
  transform: scale(1.05) translateY(-10px);
}

.raise-on-hover-link {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.raise-on-hover-link:hover {
  -webkit-transform: scale(1.05) translateY(-2px);
  transform: scale(1.05) translateY(-2px);
}

.grid-margin {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .grid-margin-sm {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .grid-margin-md {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl {
    margin-bottom: 1rem;
  }
}

.alert {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.33;
  border: 0;
}

.alert .close {
  font-size: 12px;
  color: #ffffff;
  opacity: 1;
  line-height: 1.33;
}

.badge,
.badge-pill {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 1;
  font-weight: 400;
}

.btn {
  font-weight: 400;
}

.btn.btn-warning {
  color: #ffffff;
}

.btn:focus {
  box-shadow: none;
}

.btn-pill {
  border-radius: 50rem;
}

.btn-rounded {
  border-radius: 50%;
  padding: 10px 12px;
  font-size: 12px;
}

.btn-squared {
  border-radius: 0;
}

.social-login-btn {
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin: 0 4px;
}

.social-login-btn:hover {
  color: #ffffff;
}

.btn-solid-outline-primary {
  color: #444bf8;
  border-color: #444bf8;
  background-color: #ffffff;
}

.btn-solid-outline-primary:hover {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-solid-outline-primary:focus,
.btn-solid-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-solid-outline-primary.disabled,
.btn-solid-outline-primary:disabled {
  color: #444bf8;
  background-color: transparent;
}

.btn-solid-outline-primary:not(:disabled):not(.disabled):active,
.btn-solid-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-solid-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-solid-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background-color: #ffffff;
}

.btn-solid-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-solid-outline-secondary:focus,
.btn-solid-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-solid-outline-secondary.disabled,
.btn-solid-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-solid-outline-secondary:not(:disabled):not(.disabled):active,
.btn-solid-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-solid-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-solid-outline-success {
  color: #00c9a6;
  border-color: #00c9a6;
  background-color: #ffffff;
}

.btn-solid-outline-success:hover {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-solid-outline-success:focus,
.btn-solid-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-solid-outline-success.disabled,
.btn-solid-outline-success:disabled {
  color: #00c9a6;
  background-color: transparent;
}

.btn-solid-outline-success:not(:disabled):not(.disabled):active,
.btn-solid-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-solid-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-solid-outline-info {
  color: #2dd2f6;
  border-color: #2dd2f6;
  background-color: #ffffff;
}

.btn-solid-outline-info:hover {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-solid-outline-info:focus,
.btn-solid-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-solid-outline-info.disabled,
.btn-solid-outline-info:disabled {
  color: #2dd2f6;
  background-color: transparent;
}

.btn-solid-outline-info:not(:disabled):not(.disabled):active,
.btn-solid-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-solid-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-solid-outline-warning {
  color: #f1be24;
  border-color: #f1be24;
  background-color: #ffffff;
}

.btn-solid-outline-warning:hover {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-solid-outline-warning:focus,
.btn-solid-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-solid-outline-warning.disabled,
.btn-solid-outline-warning:disabled {
  color: #f1be24;
  background-color: transparent;
}

.btn-solid-outline-warning:not(:disabled):not(.disabled):active,
.btn-solid-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-solid-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-solid-outline-danger {
  color: #f12459;
  border-color: #f12459;
  background-color: #ffffff;
}

.btn-solid-outline-danger:hover {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-solid-outline-danger:focus,
.btn-solid-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-solid-outline-danger.disabled,
.btn-solid-outline-danger:disabled {
  color: #f12459;
  background-color: transparent;
}

.btn-solid-outline-danger:not(:disabled):not(.disabled):active,
.btn-solid-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-solid-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-solid-outline-light {
  color: #e4e5e7;
  border-color: #e4e5e7;
  background-color: #ffffff;
}

.btn-solid-outline-light:hover {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-solid-outline-light:focus,
.btn-solid-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-solid-outline-light.disabled,
.btn-solid-outline-light:disabled {
  color: #e4e5e7;
  background-color: transparent;
}

.btn-solid-outline-light:not(:disabled):not(.disabled):active,
.btn-solid-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-solid-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-solid-outline-dark {
  color: #252631;
  border-color: #252631;
  background-color: #ffffff;
}

.btn-solid-outline-dark:hover {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-solid-outline-dark:focus,
.btn-solid-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.btn-solid-outline-dark.disabled,
.btn-solid-outline-dark:disabled {
  color: #252631;
  background-color: transparent;
}

.btn-solid-outline-dark:not(:disabled):not(.disabled):active,
.btn-solid-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-solid-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-solid-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-solid-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-solid-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.btn-soft-primary {
  color: #444bf8;
  border-color: #444bf8;
  background-color: rgba(68, 75, 248, 0.1);
  border-color: transparent;
}

.btn-soft-primary:hover {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
  box-shadow: 0 4px 11px rgba(68, 75, 248, 0.35);
}

.btn-soft-primary:focus,
.btn-soft-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #444bf8;
  background-color: transparent;
}

.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show>.btn-soft-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #444bf8;
  border-color: #444bf8;
}

.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 75, 248, 0.5);
}

.btn-soft-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background-color: rgba(108, 117, 125, 0.1);
  border-color: transparent;
}

.btn-soft-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 4px 11px rgba(108, 117, 125, 0.35);
}

.btn-soft-secondary:focus,
.btn-soft-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.show>.btn-soft-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
.btn-soft-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-soft-success {
  color: #00c9a6;
  border-color: #00c9a6;
  background-color: rgba(0, 201, 166, 0.1);
  border-color: transparent;
}

.btn-soft-success:hover {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
  box-shadow: 0 4px 11px rgba(0, 201, 166, 0.35);
}

.btn-soft-success:focus,
.btn-soft-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: #00c9a6;
  background-color: transparent;
}

.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled).active,
.show>.btn-soft-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00c9a6;
  border-color: #00c9a6;
}

.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 166, 0.5);
}

.btn-soft-info {
  color: #2dd2f6;
  border-color: #2dd2f6;
  background-color: rgba(45, 210, 246, 0.1);
  border-color: transparent;
}

.btn-soft-info:hover {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
  box-shadow: 0 4px 11px rgba(45, 210, 246, 0.35);
}

.btn-soft-info:focus,
.btn-soft-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #2dd2f6;
  background-color: transparent;
}

.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled).active,
.show>.btn-soft-info.dropdown-toggle {
  color: #212529;
  background-color: #2dd2f6;
  border-color: #2dd2f6;
}

.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 210, 246, 0.5);
}

.btn-soft-warning {
  color: #f1be24;
  border-color: #f1be24;
  background-color: rgba(241, 190, 36, 0.1);
  border-color: transparent;
}

.btn-soft-warning:hover {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
  box-shadow: 0 4px 11px rgba(241, 190, 36, 0.35);
}

.btn-soft-warning:focus,
.btn-soft-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: #f1be24;
  background-color: transparent;
}

.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled).active,
.show>.btn-soft-warning.dropdown-toggle {
  color: #212529;
  background-color: #f1be24;
  border-color: #f1be24;
}

.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 190, 36, 0.5);
}

.btn-soft-danger {
  color: #f12459;
  border-color: #f12459;
  background-color: rgba(241, 36, 89, 0.1);
  border-color: transparent;
}

.btn-soft-danger:hover {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
  box-shadow: 0 4px 11px rgba(241, 36, 89, 0.35);
}

.btn-soft-danger:focus,
.btn-soft-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: #f12459;
  background-color: transparent;
}

.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled).active,
.show>.btn-soft-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f12459;
  border-color: #f12459;
}

.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 36, 89, 0.5);
}

.btn-soft-light {
  color: #e4e5e7;
  border-color: #e4e5e7;
  background-color: rgba(228, 229, 231, 0.1);
  border-color: transparent;
}

.btn-soft-light:hover {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
  box-shadow: 0 4px 11px rgba(228, 229, 231, 0.35);
}

.btn-soft-light:focus,
.btn-soft-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #e4e5e7;
  background-color: transparent;
}

.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled).active,
.show>.btn-soft-light.dropdown-toggle {
  color: #212529;
  background-color: #e4e5e7;
  border-color: #e4e5e7;
}

.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 229, 231, 0.5);
}

.btn-soft-dark {
  color: #252631;
  border-color: #252631;
  background-color: rgba(37, 38, 49, 0.1);
  border-color: transparent;
}

.btn-soft-dark:hover {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
  box-shadow: 0 4px 11px rgba(37, 38, 49, 0.35);
}

.btn-soft-dark:focus,
.btn-soft-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #252631;
  background-color: transparent;
}

.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled).active,
.show>.btn-soft-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #252631;
  border-color: #252631;
}

.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-soft-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(37, 38, 49, 0.5);
}

.card .card-title {
  font-weight: 400;
}

.card-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 64px;
  height: 64px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 28px;
}

.count-card {
  width: 291px;
  margin-left: 88px;
  border-radius: 12px;
}

@media screen and (max-width: 576px) {
  .count-card {
    width: 290px;
    margin-left: 0;
    margin-top: 30px;
  }
}

.count-card .card-img {
  border-radius: 12px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.count-card .card-img-overlay {
  left: -30%;
  padding-top: 2.25rem;
}

@media screen and (max-width: 576px) {
  .count-card .card-img-overlay {
    left: 0;
  }
}

.count-card .count-box {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 130px;
  height: 130px;
  margin: 9px;
  border-radius: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .count-card .count-box {
    width: 110px;
    height: 110px;
  }
}

@media screen and (max-width: 576px) {
  .count-card .count-box {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 992px) {
  .team-card {
    max-width: 267px;
  }
}

.team-card .designation {
  color: #0f64ff;
  margin-bottom: 11px;
}

.team-card .social-links a {
  color: #000000;
  font-size: 1rem;
  margin-right: 6px;
}

.team-card .social-links a.icon-fb:hover {
  color: #444bf8;
}

.team-card .social-links a.icon-twitter:hover {
  color: #2dd2f6;
}

.team-card .social-links a.icon-insta:hover {
  color: #f12459;
}

.experience-card {
  display: -webkit-box;
  display: flex;
  margin-top: 22px;
  margin-bottom: 22px;
}

.experience-card .experiance-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 27px;
}

.grid-margin {
  margin-bottom: 20px;
}

.card-group .card {
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.card-group .card:hover {
  background-color: #e5e5e5;
}

.carousel-caption {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carousel-caption * {
  color: #ffffff;
}

.carousel-caption p {
  font-size: 16px;
  font-weight: 500;
}

.carousel-indicators li {
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  bottom: auto;
  width: 30px;
  height: 30px;
  background-color: #111;
  border: 2px solid #111;
  color: #444bf8;
  color: #fff;
  border-radius: 50%;
  opacity: 1;
  font-size: 12px;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-prev:active,
.carousel-control-next:hover,
.carousel-control-next:focus,
.carousel-control-next:active {
  background-color: #444bf8;
}

.carousel-control-prev {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.carousel-control-next {
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.dropdown-toggle::after {
  content: "\F140";
  font-family: "Material Design Icons";
  border: none;
  vertical-align: middle;
}

.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.125);
  padding-bottom: 0;
}

.dropdown-menu .dropdown-item {
  padding: 8px 24px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.dropdown-menu .dropdown-item .dropdown-item-icon {
  margin-right: 10px;
  font-size: 18px;
}

footer {
  font-weight: 300;
}

footer .footer-content {
  padding-bottom: 120px;
}

footer .navbar {
  padding-left: 0;
  padding-right: 0;
}

.footer-social-links {
  color: #777373;
}

.footer-social-links a {
  color: #777373;
  margin-right: 10px;
  font-size: 20px;
}

.footer-social-links a:last-child {
  margin-right: 0;
}

.footer-social-links a:hover {
  color: #000000;
}

.footer-social-links a.icon-fb:hover {
  color: #444bf8;
}

.footer-social-links a.icon-twitter:hover {
  color: #2dd2f6;
}

.footer-social-links a.icon-insta:hover {
  color: #f12459;
}

.footer-social-links a.icon-behance:hover {
  color: #444bf8;
}

.footer-social-links a.icon-dribbble:hover {
  color: #f12459;
}

.footer-social-links a.icon-github:hover {
  color: #000000;
}

.input-group-text {
  background-color: transparent;
  border: none;
  padding: 0 1rem;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e7eaf3;
}

.input-group {
  border: 1px solid #e7eaf3;
}

.input-group .input-group-prepend .input-group-text {
  padding-left: 19.5px;
  padding-right: 6px;
}

.input-group .input-group-append .input-group-text {
  padding-right: 19.5px;
  padding-left: 6px;
}

.input-group .form-control {
  border: none;
  background-color: transparent;
}

.input-group .form-control:not(:first-child) {
  padding-left: 0;
}

.input-group .form-control:not(:last-child) {
  padding-right: 0;
}

.form-control-pill,
.input-group-pill {
  border-radius: 50rem;
}

.input-group-pill .form-control:first-child {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.input-group-pill .form-control:last-child {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  color: #00c9a6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%2300c9a6' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /%3E%3C/svg%3E");
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  color: #f12459;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23f12459' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E");
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e7eaf3;
  box-shadow: none;
}

.form-control.input-rounded {
  border-radius: 50rem;
}

.form-check {
  padding-left: 30px;
}

.form-check .form-check-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-check .form-check-input[type="checkbox"]~.form-check-label::before {
  border-radius: 2px;
}

.form-check .form-check-input[type="checkbox"]:checked~.form-check-label::before {
  content: "\F12C";
}

.form-check .form-check-input[type="radio"]~.form-check-label::before {
  border-radius: 50%;
}

.form-check .form-check-input[type="radio"]:checked~.form-check-label::before {
  content: "\F12F";
}

.form-check .form-check-input:disabled~.form-check-label::before {
  opacity: 0.5;
}

.was-validated .form-check .form-check-input:valid~.form-check-label::before {
  background-color: #00c9a6;
  border-color: #00c9a6;
  color: #ffffff;
}

.was-validated .form-check .form-check-input:invalid~.form-check-label::before {
  background-color: #f12459;
  border-color: #f12459;
  color: #ffffff;
}

.form-check .form-check-input.is-valid~.form-check-label::before {
  background-color: #00c9a6;
  border-color: #00c9a6;
  color: #ffffff;
}

.form-check .form-check-input.is-invalid~.form-check-label::before {
  background-color: #f12459;
  border-color: #f12459;
  color: #ffffff;
}

.form-check .form-check-label {
  display: inline-block;
  position: relative;
  font-size: 12px;
  line-height: 30px;
}

.form-check .form-check-label::before {
  content: "";
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 1px solid #e7eaf3;
  margin-right: 11px;
  position: absolute;
  left: -30px;
  top: 2px;
  font-family: "Material Design Icons";
  color: #000000;
  text-align: center;
  line-height: 26px;
}

.custom-switch .custom-control-label::after {
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
  background-color: #444bf8;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.contact-form input {
  height: 50px;
  border-radius: 50rem;
}

.solbrismaritime-dash-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background-image: url(../images/header-bg-1.jpg);
  height: 576px;
  background-size: cover;
  background-repeat: no-repeat;
}

.solbrismaritime-dash-header-just-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background-image: url(../images/header-bg-1.jpg);
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
}

.no-pad {
  padding-top: unset !important;
  padding-bottom: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
}

.no-pad-y {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.no-pad-x {
  padding-left: unset !important;
  padding-right: unset !important;
}

.solbrismaritime-dash-header .solbrismaritime-dash-header-content {
  margin-top: auto;
  margin-bottom: auto;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.solbrismaritime-dash-header.header-navbar-only {
  height: auto;
}

.solbrismaritime-dash-header.header-no-bg-img {
  background-image: none;
}

.solbrismaritime-dash-header.header-bg-2,
.solbrismaritime-dash-header-just-nav.header-bg-2 {
  background-repeat: no-repeat;
  background-image: url(/ep/preferences/get/banner_front);
  background: linear-gradient(90deg,
      rgba(0, 81, 255, 0.514),
      rgba(104, 232, 255, 0.685)),
    url(/ep/preferences/get/banner_front);
  background-size: cover;
}

.solbrismaritime-dash-about-us.header-bg,
.solbrismaritime-dash-header.header-bg,
.solbrismaritime-dash-header-just-nav.header-bg {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-image: url(/static/img/snippets/dylan-mcleod-4zqvu9dyvSk-unsplash.jpg);
  background: linear-gradient(-90deg,
      rgba(0, 81, 255, 0.514),
      rgba(104, 232, 255, 0.685)),
    url(/static/img/snippets/dylan-mcleod-4zqvu9dyvSk-unsplash.jpg);
  background-size: cover;
}

.solbrismaritime-dash-about-us.header-bg-cart,
.solbrismaritime-dash-header.header-bg-cart,
.solbrismaritime-dash-header-just-nav.header-bg-cart {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-image: url(/static/img/front/andre-gaulin-I4xbI6F8MXA-unsplash.jpg);
  background: linear-gradient(-90deg,
      rgba(0, 81, 255, 0.514),
      rgba(104, 232, 255, 0.685)),
    url(/static/img/front/andre-gaulin-I4xbI6F8MXA-unsplash.jpg);
  background-size: cover;
}

.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* PRODUCT CSS */

.move-icon-right-on-hover i.fas {
  transition: all 0.3s ease !important;
}

.move-icon-right-on-hover:hover i.fas {
  transform: translateX(100%);
  color: #fff;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/* .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
    grid-gap: 10px;
} */

img.center {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
  height: 15em;
  width: auto;
}

.discount-badge {
  padding: 0 10px;
  display: inline-block;
  font-weight: bold;
  border: 2px dashed #111;
  white-space: nowrap;
  -webkit-transition: all 0.16s ease;
  transition: all 0.16s ease;
  position: absolute;
  top: 20px;
  font-size: 16px;
  left: -60px;
}

.order-wrapper {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
  grid-gap: 10px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.product-image {
  max-height: 150px;
}

.product-thumbnail {
  max-height: 100px;
}

.product-thumbnail-small {
  max-height: 50px;
}

.product-large-image {
  max-height: 500px;
  width: auto;
  max-width: 100%;
  height: auto;
}

.carousel-item.active {
  display: flex;
}

.carousel-item.carousel-item-next,
.carousel-item.carousel-item-left,
.carousel-item.carousel-item-right,
.carousel-item.carousel-item-prev {
  display: flex;
}

.inventory-status-wrapper {
  position: absolute;
  bottom: -10px;
  left: -3px;
  width: 100%
}

.inventory-status {
  font-size: 12px;
  color: #999999;
}

.product-price-wrapper {
  position: absolute;
  bottom: 5px;
  margin-top: 10px;
  /* left: 20px; */
  width: 100%;
}

.product-image-carousel {
  margin-left: 15px;
  margin-right: 15px;
}

.product-image-container {
  margin-right: 40px;
  margin-top: 75px;
  margin-left: 10px;
}

.grid-sizer,
.grid-item {
  width: 50%;
}

/* 2 columns */

.grid-item--height2 {
  height: 40%;
}

.grid-item--height3 {
  height: 60%;
}

.grid-item--height4 {
  height: 100%;
}

.product-purchase-button {
  position: absolute;
  margin-right: -15px;
  bottom: 5px;
  margin-top: 10px;
  right: 35px;
  font-size: 12px;
}

.product-purchase-button button {
  color: black;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.product-price-box {
  display: flex;
  flex-direction: column;
  background-color: #eceff1;
  height: 305px;
  margin-top: 94px !important;
  width: 100%;
  margin-top: 25px;
  margin-right: 10px;
  padding: 20px 15px 20px 15px;
  position: relative;
}

.product-price-box .attributes label {
  position: absolute;
  top: -20px;
}

.product-price-box .attributes {
  position: relative;
  margin-left: 5px;
}

.dash-card {
  margin-left: 5px;
  margin-top: 10px;
}

.mivu-stat-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.mivu-stat-grid .dash-card.mivu-stat-blue {
  color: #fff;
  background-color: var(--primary);
}

.mivu-stat-grid .dash-card.mivu-stat-blue h1 {
  color: #fff;
}

.mivu-stat-grid .dash-card.mivu-stat-red {
  color: #fff;
  background-color: var(--danger);
}

.mivu-stat-grid .dash-card.mivu-stat-red h1 {
  color: #fff;
}

.mivu-stat-grid .dash-card.mivu-stat-orange {
  color: #fff;
  background-color: var(--warning);
}

.mivu-stat-grid .dash-card.mivu-stat-orange h1 {
  color: #fff;
}

.mivu-stat-grid .dash-card.mivu-stat-green {
  color: #fff;
  background-color: var(--success);
}

.mivu-stat-grid .dash-card.mivu-stat-green h1 {
  color: #fff;
}

.swiper-pagination.swiper-pagination-not-image {
  position: unset !important;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.h-300px {
  height: 300px !important;
  width: 200px !important;
}

@media (max-width: 415px) {
  .dash-card {
    margin-left: 0;
    margin-top: 10px;
  }

  .mivu-stat-grid {
    grid-template-columns: 1fr !important;
  }
}

.product-price-box .before-price {
  font-size: 11px;
  position: relative;
}

.product-price-box .inventory-status {
  font-size: 16px;
}

.product-price-box .product-purchase-button {
  right: unset;
  bottom: 13px;
}

.product-price-box .add-to-cart-button {
  margin-top: 20px;
}

.product-price-box .add-to-cart-button button {
  color: black;
  font-size: 16px;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: auto;
  padding-right: auto;
  width: 100%;
}

.product-price-box .now-price {
  font-size: 28px;
  color: #4a4a4a;
  font-weight: 700;
}

.product-body {
  top: 180px;
  position: absolute;
  height: 241px;
  width: 100%;
  padding-right: 18px;
}

/* .title-container {
    max-height: 60px;
} */

.product-display-box h2 {
  font-family: MiVUText, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #222;
  font-size: 30px;
  /* margin-top: 10px; */
  margin-top: 0;
}

.product-display-box .product-image-hover:hover {
  border-color: #ccc;
  cursor: pointer;
  border: 2px solid #ccc !important;
}

.product-display-box {
  /* border: solid #ccc 1px;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px 2px 3px 0px rgba(150, 150, 150, 1);
    box-shadow: 0px 2px 3px 0px rgba(150, 150, 150, 1); */
}

.hotlinks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  overflow-y: auto;
}

.hotlinks a {
  margin-right: 5px;
}

.box.order-box {
  color: black;
  position: relative;
  max-width: 252.5px;
  padding: 10px;
  height: 430px;
  margin-top: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
  border: 2px dotted #e7eaf3;
  justify-self: center;
  margin: 5px;
}

.bootstrap-select.bs-container .dropdown-menu {
  max-width: 100% !important;
}

.is-btn {
  border-radius: 10px !important;
}

.box:not(.order-box) {
  color: black;
  position: relative;
  max-width: 252.5px;
  padding: 10px;
  height: 430px;
  margin-top: 10px;
  font-size: 14px;
  /* transition: all 0.3s ease; */
  /* border: 2px dotted #e7eaf3; */
  justify-self: center;
  width: 252.5px;
  margin: 5px;
}

.form-group .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-inline .bootstrap-select {
  margin-bottom: 0;
  height: auto !important;
}

.product-name {
  font-family: Roboto, Arial;
  font-weight: 700;
  margin: 10px 0 5px 0;
  line-height: 18px !important;
}

.italic {
  font-style: italic;
}

.product-page-product-name {
  font-family: Roboto, Arial;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1 !important;
}

.product-before-price {
  font-size: 11px;
  bottom: 22px;
  left: 0px;
  position: absolute;
}

.product-description {
  margin-top: 0;
  font-family: Roboto, Arial;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 50px;
}

.button-hover {
  transition: all .2s ease;
}

.button-hover:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.button-hover:hover>* {
  color: #fff;
}

.inventory-status.bigger {
  font-size: 16px;
}

.inventory-status.is-icon-not .mdi.mdi-checkbox-blank {
  color: var(--danger);
}

.inventory-status .mdi.mdi-checkbox-blank-off-outline {
  color: var(--warning);
}

.inventory-status:not(.is-icon-not) .mdi.mdi-checkbox-blank {
  color: var(--success);
}

.inventory-status .mdi.mdi-checkbox-intermediate {
  color: var(--yellow);
}

.inventory-status .mdi.mdi-close-box {
  color: var(--danger);
}

.product-price {
  font-weight: 700;
  font-size: 20px;
}

.discount-rectangle .bubble-text {
  color: #fff;
}

.discount-bubble .bubble-text {
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 600;
  white-space: nowrap;
}

.discount-rectangle {
  background-color: #1782d9;
  border-radius: 0;
  position: absolute;
  z-index: 10;
  bottom: 40px;
  left: 10px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: 700;
}

.discount-bubble {
  position: absolute;
  top: 50%;
  font-size: 50px;
  margin-top: -25px;
  left: 5px;
  z-index: 10;
  border-radius: 50%;
  background-color: #1782d9;
  width: 1em;
  height: 1em;
}

body:not(.disabled) .box:hover {
  /* border: 2px solid; */
  /* border-color: #ccc; */
  box-shadow: inset 0 0 0 3px #e6e6e6;
  cursor: pointer;
  /* transform: scale(1.06, 1.06); */
}

/* END PRODUCT CSS */

/**/

.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 40px;
  background-color: #353b48;
  border-radius: 30px;
  padding: 10px;
}

.search_input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 20px;
  transition: width 0.1s linear;
}

.searchbar:hover>.search_input {
  padding: 0 10px;
  width: 100%;
  caret-color: red;
  transition: width 0.1s linear;
}

.searchbar:hover>.search_icon {
  color: #e74c3c;
  margin-top: -18px;
}

.bg-gray {
  background-color: #f5f5f5;
}

.bg-gray-dark {
  background-color: #d4d4d4;
}

.mi-bg-gray {
  background-color: #f5f5f5;
}

.mi-bg-gray-dark {
  background-color: #d4d4d4;
}

.sub-header {
  /* visibility: hidden; */
  /* opacity: 0; */
  background-color: #f5f5f5;
  transition: visibility 0s 0.4s, opacity 0.4s linear;
}

.sub-header {
  /* position: -webkit-sticky; */
  /* Safari */
  /* position: sticky; */
  /* top: 0; */
  /* top: 80px !important; */
  background-color: #f5f5f5 !important;
  /* box-shadow: 0 1px rgba(151, 164, 175, 0.4) !important; */
  padding: unset !important;
}

.categories {
  /* height: 100%; */
  max-width: unset !important;
  width: 100%;
  white-space: nowrap;
}

.categories ul {
  height: 100%;
  width: 100%;
  list-style: none;
  margin-top: unset;
  margin-bottom: unset;
}

.category-icon {
  display: none;
  font-size: 20px;
}

.category-text {
  display: unset;
  font-size: 13px;
}

.container.d-flex.ml-0.bg-white {
  max-width: 66.6666666667vw;
}

.fa-40px {
  font-size: 40px;
}

.fa-40px.fa-check {
  font-size: 32px;
}

.fa-40px.fa-user-clock {
  font-size: 30px;
}

.product-still-image {
  position: relative;
}

@media screen and (max-width: 1200px) {
  .categories {
    margin-left: 0;
    margin-right: 0 !important;
    padding-right: 0px;
    padding-left: 0px;
  }

  .cats,
  .kittens {
    padding-left: 0;
    padding-right: 0;
    padding-inline-start: unset !important;
    padding-inline-end: unset !important;
  }
}

@media screen and (max-width: 1060px) {
  .category-icon {
    display: unset;
  }

  .categories {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .category-text:not(.show-category-text) {
    display: none !important;
  }

  .category-text {
    font-size: 24px;
  }

  .category-text.show-category-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 840px) {
  .container.d-flex.ml-0.bg-white {
    max-width: unset !important;
    width: 100% !important;
  }

  .product-purchase-button {
    right: 20px;
    bottom: 15px;
  }
}


@media screen and (max-width: 827px) {
  .wrapper .box {
    height: 250px !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 5px 0px !important;
  }

  .product-price-wrapper {
    position: absolute;
    bottom: 5px;
    margin-top: 10px;
    /* left: 20px; */
    width: 30%;
  }

  .box .product-still-image {
    display: flex !important;
    max-width: 150px;
    position: relative;
  }

  .box .product-body {
    top: 0;
    position: absolute;
    height: 241px;
    display: contents;
  }

  .box .product-body {
    top: 0;
    position: absolute;
    height: 241px;
    display: contents;
  }

  .box .product-description {
    position: absolute;
    width: auto;
    margin-right: 5px;
    left: 170px;
    top: 60px;
  }

  .box .product-price-wrapper {
    position: absolute;
    bottom: 10px;
    margin-top: 10px;
    left: 170px;
  }

  .box .inventory-status-wrapper {
    bottom: 45px;
    left: -1px;
  }

  .box .product-name {
    /* position: absolute;
        width: auto;
        left: 170px;
        top: 10px; */
    margin-top: 0;
  }

  .box>.row {
    height: 100%;
  }

  .product-purchase-button {
    right: 20px;
  }
}

body.disabled {
  margin: 0;
  /* margin-right: 10px; */
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 514px) {
  .box {
    max-width: unset;
    height: 200px;
  }

  .product-purchase-button {
    right: 35px;
  }

  .wrapper .box {
    height: 250px !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 5px 0px !important;
  }

  .order-body {
    width: 100%;
  }

  .wrapper {
    display: flex;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
    grid-template-columns: unset;
    grid-gap: unset;
  }

  .product-body {
    top: unset;
    position: unset;
    height: unset;
  }

  .solbris-col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  /* .product-price-wrapper {
        position: unset;
        bottom: unset;
        margin-top: unset;
    }

    .product-purchase-button {
        position: unset;
        bottom: unset;
        margin-top: unset;
        right: unset;
    } */
}

@media screen and (max-width: 370px) {

  .product-price-wrapper {
    position: absolute;
    bottom: 5px;
    margin-top: 10px;
    /* left: 20px; */
    width: 30%;
  }

  .box .product-still-image {
    display: flex !important;
    max-width: 100px;
    position: relative;
  }

  .box .product-description {
    position: absolute;
    width: auto;
    margin-right: 5px;
    left: 130px;
    top: 60px;
  }

  .box .product-price-wrapper {
    position: absolute;
    bottom: 10px;
    margin-top: 10px;
    left: 15px;
    width: 100% !important;
  }

  .box .inventory-status-wrapper {
    bottom: 45px;
    left: -1px;
  }

  .product-purchase-button {
    right: 20px;
  }
}

.initialize-payment-button {
  transition: all 0.2s ease;
  height: 44px;
}

.initialize-payment-button:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.cats,
.kittens {
  display: flex;
  justify-content: space-between;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  flex-wrap: wrap;
}

.cats li.featured,
.kittens li.featured {
  background-color: #354d6b;
}

.badge.featured {
  font-weight: 800 !important;
}

.cats li.featured:hover,
.kittens li.featured:hover {
  background-color: #ccc !important;
}

.cats li.featured:hover i,
.kittens li.featured:hover i,
.cats li.featured:hover p,
.kittens li.featured:hover p {
  color: #000 !important;
}

.cats li.featured i,
.kittens li.featured i,
.cats li.featured p,
.kittens li.featured p {
  color: #fff !important;
}

.cats,
.kittens li {
  position: relative;
}

.cats li .featured,
.kittens li .featured {
  position: absolute;
  top: -10px;
  right: 5px;
}

.footer-navi {
  display: flex;
  flex-wrap: wrap;
}

.hover-success:hover {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.hover-danger:hover {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.topmost {
  z-index: 1031 !important;
}

.categories ul li {
  height: 100%;
  text-align: center;
  display: table;
  padding: 10px 10px;
  transition: width 0.2s;
  flex: 1;
}

.categories ul li p {
  display: block;
  display: table-cell;
  vertical-align: middle;
}

.categories ul li:hover {
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.7s;
}

.search_icon {
  height: 20px;
  font-size: 20px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-decoration: none;
}

.solbris-sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1032;
  top: 80px;
  right: 0;
  background-color: #fff;
  padding-top: 60px;
  transition: width 0.5s;
}

@media (max-width:997px) {
  .solbris-sidebar {
    height: 100%;
    /* height: calc(100% - 50px); */
    /* 100% Full-height - height of button bottom */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1032;
    /* Stay on top */
    top: 80px;
    right: 0;
    background-color: #fff;
    /* Black*/
    overflow-x: hidden;
    overflow-y: overlay;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: width 0.5s;
    /* 0.5 second transition effect to slide in the sidebar */
  }
}

.solbris-sidebar.solbris-open .navbar-nav:not(.navbar-icon-container) {
  align-items: unset !important;
}

/* The sidebar links */

.solbris-sidebar {
  transition: all 0.3s ease;
  opacity: 0;
}

.solbris-sidebar.solbris-open {
  transition: all 0.3s ease;
  opacity: 1;
}

.solbris-sidebar .navbar-icon-container {
  transition: all 0.3s ease;
  overflow-wrap: revert;
  opacity: 0;
}

.solbris-sidebar.solbris-open .navbar-icon-container {
  opacity: 1;
}

.solbris-sidebar .nav-link {
  display: none !important;
  transition: all 0.3s ease;
  overflow-wrap: revert;
  opacity: 0;
}

.solbris-sidebar.solbris-open .nav-link {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: black !important;
  display: block !important;
  opacity: 1;
  transition: all 0.3s ease;
}

.solbris-sidebar.solbris-open .nav-item {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.solbris-sidebar.solbris-open li {
  height: 100%;
}

.solbris-sidebar.solbris-open .navbar-icon-container .nav-item {
  width: 100%;
  text-align: center;
}

.nav-item-left-unset {
  text-align: unset !important;
}

.solbris-sidebar.solbris-open li:hover {
  background-color: #ccc;
}

/* When you mouse over the navigation links, change their color */

.solbris-sidebar.solbris-open a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */

.solbris-sidebar .closebtn {
  display: none;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.solbris-sidebar.solbris-open .closebtn {
  display: block;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the solbris-sidebar */

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.solbris-open {
  width: 250px;
}

.solbris-open-extend {
  top: 0;
}

#sticky-totop .mdi {
  font-size: 64px;
  color: #616161;
}

#sticky-totop {
  display: none;
  position: fixed;
  bottom: -10px;
  right: 0;
  z-index: 9999;
}

#sticky-totop.active {
  display: unset;
}

.long-gray-box {
  /* width: auto; */
  /* height: 50px; */
  background-color: #e8e8e8;
  padding: 5px 5px 0 5px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 400 !important;
  text-align: center;
  overflow-x: hidden;
}

.long-white-box {
  /* width: auto; */
  /* height: 50px; */
  background-color: #fff;
  padding: 5px 5px 0 5px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 400 !important;
  text-align: center;
  overflow-x: hidden;
}

.long-white-box hr,
.long-gray-box hr {
  margin-bottom: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  width: 100vw;
  margin-left: -5px;
  margin-top: 6.5px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 11;
}

.long-gray-box .top-right {
  font-size: 16px;
}

a.box:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
  .solbris-sidebar.solbris-open {
    padding-top: 15px;
  }

  .solbris-sidebar.solbris-open a {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .long-gray-box .top-right {
    font-size: 10px;
  }

  .solbris-open {
    width: 100%;
  }

  .mid-text {
    margin-top: 25px;
  }

  .cats,
  .kittens {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .solbris-navbar {
    display: none;
  }
}

/**/

.header-top {
  height: 500px;
}

.text-bold {
  font-weight: 600;
}

.text-sm-bold {
  font-weight: 400;
}

.vertical-divider {
  border-left: 3px solid black;
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-white {
  border-left: 3px solid white;
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-danger {
  border-left: 3px solid var(--danger);
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-warning {
  border-left: 3px solid var(--warning);
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-info {
  border-left: 3px solid var(--info);
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-primary {
  border-left: 3px solid var(--primary);
  height: 100px;
  position: absolute;
  left: 50%;
}

.vertical-divider-main {
  border-left: 3px solid rgba(3, 113, 238, 0.589);
  height: 100px;
  left: 50%;
}

p.bigger {
  font-size: 20px;
}

small.bigger {
  font-size: 16px;
}

.keep-spacing {
  white-space: pre-line;
}

.lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  position: fixed;
  display: none;
  z-index: 9999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.lightbox-container {
  position: relative;
  max-width: 960px;
  margin: 7% auto;
  display: block;
  padding: 0 3%;
  height: auto;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .lightbox-container {
    margin-top: 10%;
  }
}

@media screen and (max-width: 414px) {
  .lightbox-container {
    margin-top: 13%;
  }
}

.lightbox-content {
  box-shadow: 0 1px 6px fade(black, 70%);
}

.lightbox-close {
  text-transform: uppercase;
  background: transparent;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  display: block;
  border: none;
  color: white;
  top: -30px;
  right: 4%;
}

.lightbox-close:focus {
  outline: none;
}

.video-container {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  height: 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.contact-list {
  list-style: none;
  padding: 10px 0;
}

.contact-list .contact-list-item {
  padding: 5px 0;
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
}

.contact-list .contact-list-item .profile-pic {
  display: -webkit-box;
  display: flex;
  width: 30px;
  height: 30px;
  position: relative;
  padding: 0;
  -webkit-box-align: center;
  align-items: center;
}

.contact-list .contact-list-item .profile-pic img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.contact-list .contact-list-item .profile-pic::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
}

.contact-list .contact-list-item .profile-pic.online::before {
  background-color: #00c9a6;
}

.contact-list .contact-list-item .profile-pic.away::before {
  background-color: #f1be24;
}

.contact-list .contact-list-item .profile-pic.busy::before {
  background-color: #f12459;
}

.contact-list .contact-list-item .profile-pic.offline::before {
  background-color: #e4e5e7;
}

.contact-list .contact-list-item .contact-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 10px;
}

.contact-list .contact-list-item .contact-details .contact-name {
  font-weight: 500;
  color: #000000;
  margin-bottom: 0;
}

.contact-list .contact-list-item .contact-details .contact-designation {
  font-size: 12px;
  margin-bottom: 0;
}

.comment-list-item {
  padding-bottom: 40px;
  padding-top: 40px;
  border-bottom: 1px solid #e7eaf3;
}

.comment-list-item:last-child {
  border-bottom: none;
}

.comment-list-item-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.comment-list-item-header .pro-pic img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.comment-list-item-header .user-details {
  padding-left: 12px;
  margin-right: auto;
}

.comment-list-item-header .user-details .user-name {
  margin-bottom: 0;
}

.comment-list-item-header .user-details .date-published {
  margin-bottom: 0;
  font-size: 14px;
}

.comment-list-item-header i {
  font-size: 15px;
}

.login-page {
  background-color: #00031d;
  background-image: url("../images/login-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
}

.login-card {
  background-color: #252631;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .login-card {
    width: 336px;
  }
}

.login-card .card-body {
  padding: 40px;
}

.login-card .login-brand {
  width: 70px;
  height: 70px;
  margin-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
}

.login-card .form-group {
  margin-bottom: 1rem;
}

.login-card .form-control {
  color: #bbbbbb;
  font-weight: 400;
}

.login-card .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #bbbbbb;
  font-weight: 400;
}

.login-card .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbbbbb;
  font-weight: 400;
}

.login-card .form-control::-moz-placeholder {
  color: #bbbbbb;
  font-weight: 400;
}

.login-card .form-control::-ms-input-placeholder {
  color: #bbbbbb;
  font-weight: 400;
}

.login-card .form-control::placeholder {
  color: #bbbbbb;
  font-weight: 400;
}

.navbar {
  z-index: 1030;
}

.product-safe-description p {
  white-space: break-spaces;
  word-break: break-word;
  font-size: 18px;
}

@media screen and (min-width: 992px) {
  .navbar {
    padding: 20px 28px;
    padding-top: 10px;
  }
}

@media (max-width: 992px) {

  .navbar-expand-lg .form-inline,
  .navbar-expand-lg .nav-item,
  .navbar-expand-lg button,
  .navbar-expand-lg .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .container {
    max-width: unset !important;
  }

  .categories {
    margin-left: 0;
    margin-right: 0 !important;
  }

  .cats,
  .kittens {
    padding-left: 0;
    padding-right: 0;
  }

  .category-text {
    font-size: 24px;
  }
}

.navbar .nav-link {
  font-size: 13px;
  line-height: 1.23;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.navbar .nav-link.nav-icon {
  padding: 0 5px;
}

.navbar .nav-link i {
  font-size: 22px;
  height: 24px;
  width: 22px;
}

.navbar .dropdown-menu {
  font-size: 12px;
}

.navbar-nav {
  -webkit-box-align: center;
  align-items: center;
  z-index: 9999;
}

.navbar-brand {
  padding: 0;
  line-height: 1;
}

.navbar-brand img {
  height: 65px;
  margin: 0 !important;
}

.navbar-toggler {
  border: 0;
  padding: 12px 0;
}

.navbar-toggler:focus {
  outline: none;
}

.footer-navbar {
  padding-top: 34px;
  padding-bottom: 34px;
}

@media (max-width: 575.98px) {

  .navbar-expand-sm .form-inline,
  .navbar-expand-sm .nav-item,
  .navbar-expand-sm button,
  .navbar-expand-sm .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .dropdown-toggle {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }

  .navbar-expand-sm .dropdown-toggle::after {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .navbar-expand-sm .dropdown-menu {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: 0;
  }

  .navbar-expand-sm .dropdown:hover .dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-sm .dropdown:hover .dropdown-menu {
    display: block;
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md .form-inline,
  .navbar-expand-md .nav-item,
  .navbar-expand-md button,
  .navbar-expand-md .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .dropdown-toggle {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }

  .navbar-expand-md .dropdown-toggle::after {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .navbar-expand-md .dropdown-menu {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: 0;
  }

  .navbar-expand-md .dropdown:hover .dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-md .dropdown:hover .dropdown-menu {
    display: block;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg .form-inline,
  .navbar-expand-lg .nav-item,
  .navbar-expand-lg button,
  .navbar-expand-lg .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-toggle {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }

  .navbar-expand-lg .dropdown-toggle::after {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .navbar-expand-lg .dropdown-menu {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: 0;
  }

  .navbar-expand-lg .dropdown:hover .dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-lg .dropdown:hover .dropdown-menu {
    display: block;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl .form-inline,
  .navbar-expand-xl .nav-item,
  .navbar-expand-xl button,
  .navbar-expand-xl .btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .dropdown-toggle {
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }

  .navbar-expand-xl .dropdown-toggle::after {
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  .navbar-expand-xl .dropdown-menu {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: 0;
  }

  .navbar-expand-xl .dropdown:hover .dropdown-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .navbar-expand-xl .dropdown:hover .dropdown-menu {
    display: block;
  }
}

.navbar-expand .form-inline,
.navbar-expand .nav-item,
.navbar-expand button,
.navbar-expand .btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-expand .dropdown-toggle {
  padding-top: 17.5px;
  padding-bottom: 17.5px;
}

.navbar-expand .dropdown-toggle::after {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.navbar-expand .dropdown-menu {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin-top: 0;
}

.navbar-expand .dropdown:hover .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-expand .dropdown:hover .dropdown-menu {
  display: block;
}

.fixed-on-scroll {
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.fixed-on-scroll.fixed-on-top {
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.4);
}

.fixed-on-scroll.fixed-on-top .nav-link {
  color: #000000;
}

.fixed-on-scroll.fixed-on-top .nav-link:hover,
.fixed-on-scroll.fixed-on-top .nav-link:focus {
  color: #000000;
}

.fixed-on-scroll.fixed-on-top .navbar-toggler {
  color: #000000;
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0rem);
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0rem);
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }
}

.pagination {
  border-radius: 0;
  border: 0;
}

.pagination .page-item .page-link {
  border-radius: 0.25rem;
  border-color: #444bf8;
  border-width: 2px;
  margin-right: 18px;
  font-size: 12px;
  padding: 8px 9px;
  height: 34px;
  min-width: 34px;
  text-align: center;
  line-height: 14px;
}

.pagination .page-item .page-link:hover {
  background-color: #444bf8;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .pagination .page-item .page-link {
    height: 28px;
    min-width: 28px;
    margin-right: 4px;
    padding: 5px 7px;
  }
}

.pagination.pagination-pill .page-item .page-link {
  border-radius: 34px;
}

.Profile-header {
  border-bottom: 1px solid #e7eaf3;
  padding-top: 47px;
  padding-bottom: 58px;
  margin-bottom: 90px;
}

@media screen and (min-width: 768px) {
  .profile-intro {
    max-width: 629px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.portfolio-row {
  margin-bottom: 10px;
}

.portfolio-item {
  display: inline-block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 9px;
}

.portfolio-item:last-child {
  margin-right: 0;
}

.portfolio-item img {
  width: 100%;
}

.progress .progress-bar {
  border-radius: 2px;
}

.progress .progress-bar.progress-bar-rounded {
  border-radius: 4px;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 4px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #e4e5e7;
  border-radius: 4px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 8px;
  width: 8px;
  border-radius: 0.25rem;
  background: #444bf8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -1px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e4e5e7;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #e4e5e7;
  border-radius: 4px;
}

input[type="range"]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 0.25rem;
  background: #444bf8;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 8px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #e4e5e7;
  border-radius: 8px;
}

input[type="range"]::-ms-fill-upper {
  background: #e4e5e7;
  border-radius: 8px;
}

input[type="range"]::-ms-thumb {
  height: 8px;
  width: 8px;
  border-radius: 0.25rem;
  background: #444bf8;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e4e5e7;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #e4e5e7;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border: none;
  position: relative;
  color: #000000;
}

.nav-tabs .nav-item.show .nav-link::after,
.nav-tabs .nav-link.active::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-color: #e7eaf3;
  border-width: 1px 1px 0 0;
  border-style: solid;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-5px, 5px) rotate(-45deg);
  transform: translate(-5px, 5px) rotate(-45deg);
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
  border: none;
  color: #000000;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  font-size: 13px;
  font-weight: 500;
  padding: 18px 15px;
}

@media screen and (max-width: 576px) {
  .nav-tabs .nav-link {
    padding: 18px 6px;
  }
}

.tab-content {
  padding-top: 1rem;
}

.nav-pills.nav-pills-icon-rounded .nav-link {
  min-width: 64px;
  height: 62px;
  font-size: 20px;
  line-height: 0.6;
  color: #040404;
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e4e5e7;
  margin-right: 21px;
}

@media screen and (max-width: 576px) {
  .nav-pills.nav-pills-icon-rounded .nav-link {
    font-size: 12px;
    height: 42px;
    min-width: 44px;
    margin-right: 5px;
  }
}

.nav-pills.nav-pills-icon-rounded .show .nav-link,
.nav-pills.nav-pills-icon-rounded .nav-link.active {
  color: #ffffff;
  background-color: #444bf8;
}

.nav-pills.nav-pills-icon-text .nav-link {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  text-align: center;
  padding: 20px 18px 20px 18px;
  font-size: 13px;
  color: #000000;
  margin-right: 14px;
}

.nav-pills.nav-pills-icon-text .nav-link i {
  font-size: 20px;
  line-height: 0.6;
  margin-bottom: 7px;
}

@media screen and (max-width: 576px) {
  .nav-pills.nav-pills-icon-text .nav-link {
    margin-right: 5px;
    padding: 10px 9px 6px 9px;
  }
}

.nav-pills.nav-pills-icon-text .show .nav-link,
.nav-pills.nav-pills-icon-text .nav-link.active {
  color: #ffffff;
  background-color: #444bf8;
}

.tab-content-vertical {
  padding-top: 0;
}

.card-tab .card-header {
  background-color: #444bf8;
}

.card-tab .card-header .card-header-pills {
  margin: 0;
}

.card-tab .card-header .nav-link {
  color: #ffffff;
}

.card-tab .card-header .show .nav-link,
.card-tab .card-header .nav-link.active {
  color: #444bf8;
  background-color: #ffffff;
  border-radius: 50rem;
}

.warninglabelcontainer {
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

img.warninglabel,
a.warninglabel img {
  width: 60px;
  height: 60px;
  border: 1px solid black;
  margin-right: 5px;
  transition: all 0.3s ease;
}

img.warninglabel:hover,
a.warninglabel img:hover {
  transform: scale(1.5, 1.5);
}

/*# sourceMappingURL=solbrismaritime-dash-free.css.map */

.nav-link:hover {
  border: 2px #007bff;
  border-radius: 20px;
  color: #1000f7 !important;
}

.nav-item.active a {
  border: 2px #007bff;
  border-radius: 20px;
  color: #0019f7 !important;
}

a.anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.visible-button {
  position: relative;
  display: inline-block;
  padding: 0.5em 2em;
  cursor: pointer;
  overflow: hidden;
}

.visible-button:before,
.visible-button:after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #252525;
}

.visible-button:before {
  top: 0;
}

.visible-button:after {
  bottom: 0;
}

.visible-button:hover>*>*:before,
.visible-button:hover>*>*:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.visible-button:hover>*>*>*:before,
.visible-button:hover>*>*>*:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.visible-button>*:before,
.visible-button>*:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #252525;
}

.visible-button>*:before {
  left: 0;
}

.visible-button>*:after {
  right: 0;
}

.visible-button>*>*:before,
.visible-button>*>*:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 9;
  height: 2px;
  width: 100%;
  background-color: #168dff;
}

.visible-button>*>*:before {
  top: 0;
  -webkit-transform: translate3d(-105%, 0, 0);
  transform: translate3d(-105%, 0, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.visible-button>*>*:after {
  bottom: 0;
  -webkit-transform: translate3d(105%, 0, 0);
  transform: translate3d(105%, 0, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.visible-button>*>*>*:before,
.visible-button>*>*>*:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 2px;
  background-color: #168dff;
}

.visible-button>*>*>*:before {
  left: 0;
  -webkit-transform: translate3d(0, 105%, 0);
  transform: translate3d(0, 105%, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.visible-button>*>*>*:after {
  right: 0;
  -webkit-transform: translate3d(0, -105%, 0);
  transform: translate3d(0, -105%, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.list-group-item small {
  font-size: 13px;
}

.list-group-item.active {
  z-index: 2;
  background-color: #e9eef3;
  border-color: #e9eef3;
}

.mdi-in-table {
  position: relative;
  top: -7px;
}

.tab-1 {
  max-height: 100px;
  overflow-y: scroll;
  width: 100%;
}

.tab-2 {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
}

.tab-3 {
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.tab-4 {
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
}

.tab-5 {
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
}

.tab-6 {
  max-height: 600px;
  overflow-y: scroll;
  width: 100%;
}

.tab-7 {
  max-height: 700px;
  overflow-y: scroll;
  width: 100%;
}

.tab-8 {
  max-height: 800px;
  overflow-y: scroll;
  width: 100%;
}

.tab-9 {
  max-height: 900px;
  overflow-y: scroll;
  width: 100%;
}

.tab-10 {
  max-height: 1000px;
  overflow-y: scroll;
  width: 100%;
}

.tab-100 {
  /* THIS EDITED*/
  max-height: calc(100vh - 222px);
  /*max-height: calc(100vh - 88.5px);*/
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.tab-100-no-width {
  max-height: 100vh;
  height: 100%;
  overflow-y: scroll;
}

@media (min-width:997px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #111;
    border: 0px none #f4faff;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #f4faff;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border: 0px none #f4faff;
    border-radius: 39px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.img-whiten {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255 255 255 / 35%);
  height: 100%;
}

.front-title {
  position: absolute;
  /* width: 100%; */
  bottom: -7px;
  font-weight: 600;
  font-size: 4rem;
  color: #111;
  background-color: #fff;
  padding: 5px 25px;
  border-radius: 10px 10px 0 0;
  margin-bottom: -30px;
  box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 20%);
}

@media (min-width:997px) {
  .front-title {
    font-size: 6rem;
    padding: 5px 65px;
  }
}

.ds-collapsed-show {
  display: none;
}

.show.list-group-item {
  cursor: pointer;
}

.table-loading-animation {
  -webkit-animation: 0.6s ease-in infinite alternate forwards fade;
  animation: 0.6s ease-in infinite alternate forwards fade;
}

.table-loading-animation-keep {
  -webkit-animation: 0.6s ease-in infinite alternate forwards fade;
  animation: 0.6s ease-in infinite alternate forwards fade;
}

.input_stub,
.textarea_stub {
  color: #f3f5f6;
  font-size: 1.3em;
  font-family: Flow-Block, sans-serif;
  line-height: 0.75em;
}

.stub-text {
  display: inline-block;
  /* font-size: 1.3em; */
  font-family: Flow-Block, sans-serif;
  line-height: 0.75em;
  /* letter-spacing: 1.5px; */
  opacity: 0.3;
  -webkit-transform: scaleY(1.25);
  transform: scaleY(1.25);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stub-image {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0.3;
  pointer-events: none;
}

.stub-image_square {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.4;
  }
}

.color-on-hover-link:hover {
  background-color: #e9eef3;
  cursor: pointer;
}

.color-on-hover-link-light:hover {
  background-color: #ffffff !important;
  cursor: pointer;
}

.deleteItem:hover {
  color: var(--primary) !important;
  cursor: pointer;
}

.editItem:hover {
  color: var(--primary) !important;
  cursor: pointer;
}

.sendConfirmation:hover,
.addFeature:hover,
.icon-hover-black:hover {
  color: black !important;
  cursor: pointer;
}

.icon-hover-light:hover {
  color: var(--light) !important;
  cursor: pointer;
}

.icon-hover-primary:hover {
  color: var(--primary) !important;
  cursor: pointer;
}

.icon-hover-info:hover {
  color: var(--info) !important;
  cursor: pointer;
}

.icon-hover-success:hover {
  color: var(--success) !important;
  cursor: pointer;
}

.icon-hover-warning:hover {
  color: var(--warning) !important;
  cursor: pointer;
}

.icon-hover-danger:hover {
  color: var(--danger) !important;
  cursor: pointer;
}

.inactive {
  background-color: var(--light) !important;
}

.fStatus:hover {
  cursor: pointer;
  color: #2643e9 !important;
  background-color: rgba(203, 210, 246, 0.5) !important;
}

/* Dashboard */

.mivudash-long-search {
  height: 30px;
}

.searchable:hover {
  cursor: pointer;
}

.item-selected {
  background-color: var(--success);
}

.item-selected:hover {
  background-color: var(--danger) !important;
}

.lighter-text {
  color: #abb0be;
}

.main-color-text {
  color: #00c0cb;
}

nav {
  padding: 20px 0 40px 0;
  /* background: #eee; */
  font-size: 16px;
}

nav .navbar-left {
  float: left;
}

nav .navbar-right {
  float: right;
  position: relative;
}

nav a {
  display: inline;
  padding-left: 20px;
  color: #777777;
  text-decoration: none;
}

nav a:hover {
  color: black;
}

.container {
  margin: auto;
  width: 100%;
}

#addtocart {
  width: 250px;
}

.shopwrapper a.box {
  transform: scale(0.2, 0.2);
  transition: all 1s ease;
}

.shopwrapper a.box.loaded {
  transform: scale(1, 1);
}

.add_product_to_cart {
  z-index: 100;
}

.left-box {
  position: absolute;
  left: 0;
  top: 250px;
  width: 200px;
}

@media (max-width: 1525px) {
  .left-box {
    position: relative;
    left: unset;
    top: unset;
    width: unset;
  }
}

.shopping-cart {
  background: white;
  width: 320px;
  position: absolute;
  top: 53px;
  right: 57px;
  border-radius: 3px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform-origin: left top 0;
  -webkit-transform: scale(0);
  transform-origin: left top 0;
  transform: scale(0);
}

.shopping-badge {
  position: absolute;
  top: 15px;
  right: -6px;
  font-size: 10px;
  height: 16px;
  width: 23px;
  padding-top: 4px;
  padding-left: unset;
  padding-right: unset;
}

.loading-ellipsis {
  line-height: 103px;
  font-size: 80px;
}

@keyframes blink {

  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0;
  }

  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }

  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0;
  }
}

.loading-ellipsis span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.loading-ellipsis span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}

.loading-ellipsis span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}

#cart {
  position: relative;
}

.shopping-cart.active {
  opacity: 1;
  -webkit-transform-origin: left top 0;
  -webkit-transform: scale(1);
  transform-origin: left top 0;
  transform: scale(1);
}

.shopping-cart .shopping-cart-header {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px;
}

.shopping-cart .shopping-cart-header .shopping-cart-total {
  float: right;
}

.shopping-cart .shopping-cart-items {
  padding-top: 20px;
  padding-left: 0;
}

.shopping-cart .shopping-cart-items li {
  padding: 10px;
  list-style: none;
}

.shopping-cart .shopping-cart-items li:hover {
  background-color: #f4f4f4;
}

.shopping-cart .shopping-cart-items img {
  float: left;
  margin-right: 12px;
  max-width: 70px;
  max-height: 70px;
}

.shopping-cart .shopping-cart-items .item-name {
  display: block;
  font-size: 16px;
}

.shopping-cart .shopping-cart-items .item-detail {
  display: block;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.shopping-cart .shopping-cart-items .item-price {
  color: #00c0cb;
  margin-right: 8px;
}

.shopping-cart .shopping-cart-items .item-quantity {
  color: #abb0be;
}

.shopping-cart:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
}

.cart-icon {
  color: #515783;
  font-size: 28px;
  margin-right: 7px;
  float: left;
  margin-top: 0;
  top: 12px;
  position: absolute;
}

.cart-icon-qty {
  margin-left: 35px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
  list-style: none;
}

#stepProgressBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.step {
  text-align: center;
}

.step-text {
  margin-bottom: 10px;
  color: #28a745;
}

.bullet {
  border: 1px solid #28a745;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  color: #28a745;
  display: inline-block;
  position: relative;
  transition: background-color 500ms;
  line-height: 20px;
}

.bullet.completed,
.bullet.current {
  color: white;
  background-color: #28a745;
}

body:not(.disabled) .cursor:hover {
  cursor: pointer;
}

.footer_cat-title {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-top: 10px;
  margin-bottom: 20px;
}

._text-center {
  text-align: center !important;
}

#footer ul,
#footer ol {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  width: 100%;
}

.nav--large-padding.nav--vertical li a {
  padding-top: 10px;
  padding-bottom: 10px;
}

#footer {
  background-color: #f4faff;
}

.link-container-h a {
  display: inline;
  padding-left: unset;
  color: #777777;
  text-decoration: none;
}

.link-container-h a::before {
  content: "> ";
  color: #a0beff;
}

.link-container-h .footer-image {
  max-width: 700px;
  width: 100%;
  margin-right: 5px;
}

.link-container-h {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.link-container-w {
  width: auto;
  max-width: 375px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.p-absolute {
  position: absolute;
}

nav a:hover {
  color: black;
}

.bullet.completed:not(.last)::after {
  content: "";
  position: absolute;
  right: -100px;
  bottom: 8px;
  height: 2px;
  width: 100px;
  background-color: #28a745;
}

.large {
  font-size: 24px;
}

.payment-brand {
  margin-left: 20px;
}

button.stripeButton {
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  color: #fff;
  background: #32325d;
  white-space: nowrap;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  vertical-align: middle;
  justify-self: center;
  width: 250px;
}

button.stripeButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: #43458b;
}

button.stripeButton:focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.payment-button:hover {
  cursor: pointer;
}

.profile-tabs {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
}

.profile-tabs li {
  list-style: none;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.profile-tabs li.active {
  background-color: #6c757d;
  color: white;
  cursor: not-allowed;
}

.profile-tabs li:not(.active):hover {
  background-color: #cacaca;
  cursor: pointer;
  color: white;
}

ul.settings-tabs {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
}

ul.settings-tabs li {
  list-style: none;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

ul.settings-tabs li.active {
  background-color: #6c757d;
  color: white;
  cursor: not-allowed;
}

ul.settings-tabs li:not(.active):hover {
  background-color: #cacaca;
  cursor: pointer;
  color: white;
}

.account-tabs .tab {
  display: none;
  margin-top: 25px;
  transition: all 0.2s ease;
}

.account-tabs:last-child {
  margin-bottom: 25px;
}

.account-tabs .tab.active {
  display: block;
}

div.settings-tabs .tab {
  display: none;
  margin-top: 25px;
}

div.settings-tabs:last-child {
  margin-bottom: 25px;
}

div.settings-tabs .tab.active {
  display: block;
}

.tab .row {
  margin-top: 10px;
}

.userinfo {
  font-size: 16px;
  font-weight: 500;
  color: #818181;
}

.large-a {
  text-decoration: none;
  font-size: 20px;
  color: var(--primary);
  font-weight: 500;
}

.large-a:hover {
  text-decoration: none;
  color: var(--warning);
}

.hover-row tr:hover {
  cursor: pointer;
}

.tab p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.alert-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.alert-box-item {
  transition: all 0.3s ease;
  padding: 10px;
}

@media (max-width: 500px) {
  .tab p {
    font-size: 12px;
  }

  .alert-box-item {
    margin-top: 10px;
    width: 100%;
    text-align: end;
  }

  .alert-box-item .fas {
    font-size: 2em !important;
  }

  .alert-box-item h2 {
    font-size: 16px !important;
  }
}

.alert-box-item h2 {
  white-space: nowrap;
}

.alert-box-item .title-box {
  white-space: pre-line;
  margin-top: 15px;
  color: black;
  max-width: 250px;
  overflow: hidden;
  height: 0;
  transition: all 0.2s ease;
}

.br-10 {
  border-radius: 10px;
}

.alert-box-item:hover .title-box {
  height: 100%;
}

.alert-box-item:hover {
  background-color: #fbfbfb;
  transform: scale(1.1, 1.1);
}

a.alert-box-item:hover {
  cursor: pointer;
}

.addInventory:hover {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.removeInventory:hover {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.imgContainer {
  /* border: dashed 2px #cacaca; */
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 100%;
  position: relative;
  padding: 50px;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.imgContainer:hover {
  /* border: solid 2px #cacaca; */
  transform: scale(1.05, 1.05);
  cursor: pointer;
}

.imgContainer.active:hover {
  /* border: solid 2px var(--danger); */
  transform: scale(1.05, 1.05);
  cursor: not-allowed;
}

.imgContainer .default {
  display: none;
  position: absolute;
  border-radius: 10px;
  background-color: var(--info);
  background-color: var(--info);
  color: white;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
}

.imgContainer .default.active {
  display: unset;
}

.imgContainer .default.active p {
  font-weight: 500;
  font-size: 16px;
}

.imgContainer .delete.active span {
  display: none;
  color: white;
}

.imgContainer .delete.active span h4 {
  color: white;
}

.imgContainer .delete {
  position: absolute;
  display: none;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
}

.imgContainer .delete.active {
  display: unset;
  background-color: var(--danger);
  color: white;
  text-align: center;
  transition: all 0.2s ease;
}

.imgContainer:hover .default:not(.active) {
  display: block;
  transition: all 0.2s ease;
}

.imgContainer .default span {
  display: none;
}

.imgContainer .default:not(.active):hover span i {
  font-size: 48px;
  margin-bottom: 10px;
}

.imgContainer .default:not(.active):hover span {
  display: block;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.imgContainer .default:not(.active):hover span h4 {
  color: white;
}

.imgContainer .default:not(.active):hover p {
  display: none;
}

.imgContainer .default:not(.active):hover {
  display: flex;
  cursor: pointer;
  transform: scaleY(100%);
  z-index: 10;
  height: 100%;
  font-weight: 500;
  font-size: 24px;
}

.imgContainer .delete.active:hover {
  display: flex;
  cursor: pointer;
  transform: scaleY(100%);
  z-index: 10;
  height: 100%;
  font-weight: 500;
  font-size: 24px;
}

.imgContainer .delete.active:hover span {
  display: block;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.imgContainer .delete.active:hover span i {
  font-size: 48px;
  margin-bottom: 10px;
}

.imgContainer .delete.active:hover p {
  display: none;
}

.imgContainer .default.active p {
  font-weight: 500;
  font-size: 16px;
}

.product-thumbnail-250 {
  max-height: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.table-hover:hover {
  cursor: pointer;
}

.table-left-top {
  position: absolute;
  right: 0;
  bottom: 124px;
  z-index: 2;
}

.relative {
  position: relative;
}

.btn-hover-success:hover {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-hover-danger:hover {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn.dropdown-toggle.btn-light {
  background-color: unset !important;
  color: black !important;
}

.presentImages {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-row-gap: 10px;
}

.btn-outline {
  text-decoration: none;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.bouncy {
  transition: all 0.3s ease;
}

.bouncy.bounce {
  animation-name: grow;
  animation-duration: 1s;
  -webkit-animation-name: grow;
  -webkit-animation-duration: 1s;
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes grow {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.order-box-queue-rectangle {
  background-color: #1782d9;
  border-radius: 0;
  position: absolute;
  z-index: 10;
  left: 10px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: 700;
}

.order-box-date-rectangle {
  background-color: var(--warning);
  position: absolute;
  border-radius: 0;
  z-index: 10;
  /* top: 25px; */
  right: 10px;
  /* left: 10px; */
  font-size: 11px;
  padding: 0 12px;
  font-weight: 700;
}

.order-box-queue-rectangle-green {
  background-color: var(--success);
  border-radius: 0;
  position: absolute;
  left: 10px;
  font-size: 14px;
  padding: 0 12px;
  font-weight: 700;
}

.order-box-queue-bubble {
  position: absolute;
  top: 50%;
  font-size: 50px;
  margin-top: -25px;
  left: 5px;
  border-radius: 50%;
  background-color: #1782d9;
  width: 1em;
  height: 1em;
}

.order-box-date-rectangle .order-box-date-text {
  color: #fff;
  font-size: 15px;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  font-weight: 600;
  white-space: nowrap;
}

.order-box-date-rectangle-block .order-box-date-text {
  color: black;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  top: unset;
  left: unset;
}

.order-box-queue-rectangle .order-box-queue-text,
.order-box-queue-rectangle-green .order-box-queue-text {
  color: #fff;
}

.order-box-queue-bubble .order-box-queue-text,
.order-box-queue-bubble-green .order-box-queue-text {
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 600;
  white-space: nowrap;
}

.order-name {
  margin-top: 40px;
  margin-left: 0;
  font-family: Roboto, Arial;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 0;
  text-transform: uppercase;
}

.order-item {
  font-size: 14px;
  margin-bottom: 0;
}

.order-line {
  font-size: 12px;
  margin-bottom: 0;
}

.order-items,
.order-lines {
  margin-bottom: 1rem;
}

hr.small {
  margin-top: 10px;
  margin-bottom: 15px;
}

hr.smaller {
  margin-bottom: 5px;
  margin-top: 40px;
}

.box .row {
  margin-right: unset;
  margin-left: unset;
}

.order-payment-status {
  position: absolute;
  margin-right: -15px;
  bottom: 5px;
  margin-top: 10px;
  right: 20px;
  font-size: 12px;
}

.order-status {
  position: absolute;
  bottom: 5px;
  font-size: 12px;
}

.context-menu {
  min-width: 150px;
  min-height: 35px;
  background-color: #fafafa;
  border: dotted 1px #cacaca;
  border-radius: 4px;
  z-index: 1035;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.context-menu .ctx-item {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  text-align: end;
}

.context-menu .ctx-item i {
  float: left;
}

.context-menu .ctx-item:disabled {
  text-decoration: line-through;
  color: var(--danger);
}

.context-menu .ctx-item:hover {
  background-color: #cacaca;
  cursor: pointer;
}

.context-menu .fa-spinner {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 18px;
  margin-top: auto;
  margin-bottom: auto;
  height: 18px;
}

.mivu-collapsable {
  transition: all 0.5s ease;
  height: 0;
  display: none;
  overflow: hidden;
}

.mivu-collapsable.active {
  height: unset;
  display: grid;
  overflow: unset;
}

.mivu-collapse-btn,
.mivu-collapse-btn-middle,
.mivu-collapse-btn-down {
  transition: all 0.2s ease;
}

.mivu-collapse-btn.active h1,
.mivu-collapse-btn-middle.active h1,
.mivu-collapse-btn-down.active h1 {
  color: #fff;
}

.mivu-collapse-btn:hover,
.mivu-collapse-btn.active {
  transform: translateY(-5px);
  background-color: #cacaca;
  cursor: pointer;
}

.mivu-collapse-btn-middle:hover,
.mivu-collapse-btn-middle.active {
  margin-top: 5px;
  margin-bottom: 5px;
  transform: scale(1.02, 1.02);
  z-index: 10;
  background-color: #cacaca;
  cursor: pointer;
}

.mivu-collapse-btn-down:hover,
.mivu-collapse-btn-down.active {
  transform: translateY(5px);
  background-color: #cacaca;
  cursor: pointer;
}

.box.order-box {
  border: 2px dashed;
  border-color: #ccc;
}

.box.order-box:hover {
  border: 2px solid;
  border-color: #ccc;
  cursor: pointer;
}

.mivu-pagination {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  transition: all 0s;
  /* height: 50px; */
}

.cat-box {
  position: relative;
  display: flex;
  text-decoration: none !important;
  justify-content: center;
  min-width: 150px;
  flex: auto;
  flex-direction: column;
  width: 150px;
  color: #111;
  text-align: center;
  height: 150px;
  background-color: #f4f6ff;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 5px;
  padding: 25px;
  overflow-wrap: anywhere;
}

.cat-box:hover {
  color: #000 !important;
}

.cat-box p {
  line-height: 1;
  position: absolute;
  width: 100%;
  padding: 0 25px;
  text-align: center;
  left: 0;
  font-weight: 600;
  bottom: 30px;
}

.cat-box .mdi {
  font-size: 42px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 60px;
}

.cat-box small {
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;
  left: 0;
}

.cat-boxes {
  width: 100%;
  max-width: 100%;
  overflow-x: overlay;
  min-height: 180px;
}

.move-icon-right-on-hover:hover i {
  color: #000 !important;
}


.mivu-pagination.wrapped {
  position: absolute;
  z-index: 1030;
  bottom: 0;
  width: 100%;
}

.mivu-pagination div.mivu-page:not(:first-child) {
  border-left: 1px solid #6e6e6e;
}

.mivu-pagination div.mivu-page {
  min-width: 50px;
  background-color: #f2f2f2;
  color: #32325d;
  /* padding: 5px 10px 5px 10px; */
  transition: all 0.2s ease;
  height: 50px;
  flex: auto;
  text-align: center;
  z-index: 1032;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mivu-pagination div.mivu-page:hover,
.mivu-pagination div.mivu-page.active {
  color: white;
  background-color: #6f75b9;
  /* padding: 6px 12px 6px 12px; */
  cursor: pointer;
}

.mivu-pagination div.mivu-page.active {
  cursor: not-allowed;
}

.hide-pagination>.fas {
  font-size: 12px;
}

.hide-pagination {
  position: absolute;
  left: 5px;
  top: -15px;
  width: 25px;
  border-radius: 50%;
  height: 25px;
  background-color: #f2f2f2;
  color: #111;
  display: flex;
  z-index: 1033;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.category-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2);

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media (max-width:814px) {
  .category-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2);
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
  color: #2880cc !important;
  transform: scale(1.2, 1.2);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px !important;
  color: #000 !important;
  font-weight: 800 !important;
  transition: all .2s ease;
}

.dropdown-header {
  color: #32325d !important;
}

.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

.b-0 {
  bottom: 0;
}

.top-right {
  position: absolute;
  right: 5px;
}

.bottom-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.bottom-right {
  position: absolute;
  right: 5px;
  bottom: 0;
}

.d-table-cell {
  display: table-cell;
}

.package-manager-list td {
  padding: 10px;
}

.package-manager-list td:hover {
  cursor: not-allowed;
}

.table-striped-first tbody tr:first-of-type {
  background-color: rgba(0, 0, 0, 0.05);
}

.single-order-box {
  padding: 25px;
  margin-top: 0;
}

.badge-col {
  display: flex;
  flex-direction: column;
  place-items: flex-start;
}

.single-order-tabs {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  box-shadow: 5px black 5px;
}

.single-order-tabs .tab-link {
  flex: auto;
  padding: 10px;
  background-color: #cacaca;
  text-align: center;
  transition: all 0.2s ease;
  color: #32325d;
}

.single-order-tabs .tab-link p {
  text-align: center;
  font-weight: 700;
  vertical-align: middle;
  margin-bottom: 0;
}

.single-order-tabs .tab-link:hover,
.single-order-tabs .tab-link.active {
  background-color: #a0a0a0;
  color: white;
  cursor: pointer;
}

.single-order-box label {
  font-weight: 700;
  margin-bottom: 0;
}

.tiny-image {
  max-width: 50px;
  max-height: 50px;
  height: 50px;
}

.mi-slider {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.mi-slider.active {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.mi-slider:not(.active) {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
  transition: all 0.5s ease;
  display: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

@keyframes slide-in {
  0% {
    transform: translateX(0%);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    transform: translateX(110%);
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(0%);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    -webkit-transform: translateX(110%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translateX(-110%);
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(-110%);
    opacity: 0;
    visibility: hidden;
  }
}

.fulfilled_item .fas {
  font-size: 40px;
}

.fulfilled_item .fas.text-danger:hover {
  color: var(--success) !important;
}

.fulfilled_item .fas.text-success {
  font-size: 30px;
}

.fulfilled_item .fas.text-success:hover {
  color: var(--danger) !important;
}

.centerImage {
  display: flex;
  justify-content: center;
}

.t-0 {
  top: 0 !important;
}

.order-table .bp-1,
.order-table .bp-2,
.order-table .bp-3,
.order-table .bp-4,
.order-table .bp-5,
.order-table .bp-6,
.order-table .bp-7,
.order-table .bp-8 {
  display: table-cell;
}

.order-table .bp-block-1,
.order-table .bp-block-2,
.order-table .bp-block-3,
.order-table .bp-block-4,
.order-table .bp-block-5,
.order-table .bp-block-6,
.order-table .bp-block-7,
.order-table .bp-block-8 {
  display: block;
}

.order-table .bp-inline-1,
.order-table .bp-inline-2,
.order-table .bp-inline-3,
.order-table .bp-inline-4,
.order-table .bp-inline-5,
.order-table .bp-inline-6,
.order-table .bp-inline-7,
.order-table .bp-inline-8 {
  display: inline;
}

.cc-justify-content-center {
  display: flex !important;
  justify-content: center !important;
}

@media (max-width: 1330px) {

  .order-table .bp-1,
  .order-table .bp-inline-1,
  .order-table .bp-block-1 {
    display: none;
  }
}

@media (max-width: 1246px) {

  .order-table .bp-2,
  .order-table .bp-inline-2,
  .order-table .bp-block-2 {
    display: none;
  }
}

@media (max-width: 1150px) {

  .order-table .bp-3,
  .order-table .bp-inline-3,
  .order-table .bp-block-3 {
    display: none;
  }
}

@media (max-width: 492px) {

  .order-table .bp-4,
  .order-table .bp-inline-4,
  .order-table .bp-block-4 {
    display: none;
  }

  .breadcrumb {
    margin-left: auto;
    margin-right: auto;
  }
}

.image-viewer {
  /* display: none; */
  width: 100%;
  height: 0;
  transition: all 0.2s ease;
  background-color: #fff;
  overflow: hidden;
}

.image-viewer img {
  display: none;
  transition: all 0.2s ease;
}

.image-viewer.show img.show {
  max-width: 300px;
  display: flex;
  width: 100%;
  margin-top: 15px;
  border: dashed 2px #525f7f;
  border-radius: 10px;
}

.image-viewer.show img.show::before {
  content: "x";
}

.image-viewer.show {
  display: flex;
  height: 100%;
  justify-content: center;
}

.mivu-loading-dots:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

.mivu-loading-dots-big {
  color: black;
  font: 300 4em/150% Impact;
  font-size: 40px;
  text-align: center;
}

.mivu-loading-dots-big:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {

  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.mivunav-top .nav-link:not(.not) {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: #111;
}

.mivunav-top.on-white .nav-item:not(.active) .nav-link:not(.not) {
  color: black !important;
}

.showOnAction {
  display: none;
}

.hover {
  transition: all 0.2s ease;
}

.hover:hover {
  transform: scale(1.02, 1.02);
  cursor: pointer;
}

.card-ellipsis {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 20px;
}

.card-ellipsis:hover {
  color: var(--primary);
  cursor: pointer;
}

/*######################################*/
/*               EditorJS               */
/*######################################*/
.ejs-content b {
  font-weight: 600;
}

.editor-quote {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.editor-quote p {
  font-size: 12px;
  color: var(--gray);
  position: absolute;
  bottom: -36px;
  right: 0;
}

.editor-quote pre {
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  white-space: break-spaces;
}

.codex-editor {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}

.codex-editor .hide,
.codex-editor__redactor--hidden {
  display: none;
}

.codex-editor__redactor [contenteditable]:empty:after {
  content: "\feff ";
}

@media (min-width: 651px) {
  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 50px;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbar__actions {
    right: -5px;
  }
}

.codex-editor__loader {
  position: relative;
  height: 30vh;
}

.codex-editor__loader:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid rgba(201, 201, 204, 0.48);
  border-top-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: editor-loader-spin 0.8s linear infinite;
  animation: editor-loader-spin 0.8s linear infinite;
  will-change: transform;
}

.codex-editor-copyable {
  position: absolute;
  height: 1px;
  width: 1px;
  top: -400%;
  opacity: 0.001;
}

.codex-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
  overflow: hidden;
}

.codex-editor-overlay__container {
  position: relative;
  pointer-events: auto;
  z-index: 0;
}

.codex-editor-overlay__rectangle {
  position: absolute;
  pointer-events: none;
  background-color: rgba(46, 170, 220, 0.2);
  border: 1px solid transparent;
}

.codex-editor svg {
  fill: currentColor;
  vertical-align: middle;
  max-height: 100%;
}

::-moz-selection {
  background-color: #d4ecff;
}

::selection {
  background-color: #d4ecff;
}

.codex-editor--toolbox-opened [contentEditable="true"][data-placeholder]:focus:before {
  opacity: 0 !important;
}

@-webkit-keyframes editor-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes editor-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.ce-toolbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  will-change: opacity, transform;
  display: none;
}

@media (max-width: 650px) {
  .ce-toolbar {
    position: absolute;
    background-color: #fff;
    border: 1px solid #eaeaea;
    -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    border-radius: 4px;
    z-index: 2;
  }
}

@media (max-width: 650px) and (max-width: 650px) {
  .ce-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

@media (max-width: 650px) {
  .ce-toolbar {
    padding: 3px;
    margin-top: 5px;
  }

  .ce-toolbar--left-oriented:before {
    left: 15px;
    margin-left: 0;
  }

  .ce-toolbar--right-oriented:before {
    left: auto;
    right: 15px;
    margin-left: 0;
  }
}

.ce-toolbar--opened {
  display: block;
}

@media (max-width: 650px) {
  .ce-toolbar--opened {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.ce-toolbar__content {
  max-width: 650px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 650px) {
  .ce-toolbar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 0;
    max-width: calc(100% - 35px);
  }
}

.ce-toolbar__plus {
  color: #707684;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: -34px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ce-toolbar__plus--active,
.ce-toolbar__plus:hover {
  color: #388ae5;
}

.ce-toolbar__plus--active {
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-toolbar__plus-shortcut {
  opacity: 0.6;
  word-spacing: -2px;
  margin-top: 5px;
}

.ce-toolbar__plus--hidden {
  display: none;
}

@media (max-width: 650px) {
  .ce-toolbar__plus {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    position: static;
    -webkit-transform: none !important;
    transform: none !important;
  }
}

.ce-toolbar .ce-toolbox,
.ce-toolbar__plus {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ce-toolbar__actions {
  position: absolute;
  right: -30px;
  top: 5px;
  opacity: 0;
}

@media (max-width: 650px) {
  .ce-toolbar__actions {
    position: absolute;
    right: -28px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.ce-toolbar__actions--opened {
  opacity: 1;
}

.ce-toolbar__actions-buttons {
  text-align: right;
}

.ce-toolbar__settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: #707684;
  cursor: pointer;
  background: #eff2f5;
}

.ce-toolbar__settings-btn:hover {
  color: #1d202b;
}

@media (max-width: 650px) {
  .ce-toolbar__settings-btn {
    background: transparent;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbar__plus {
    left: 5px;
  }
}

.ce-toolbox {
  position: absolute;
  visibility: hidden;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  will-change: opacity;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 650px) {
  .ce-toolbox {
    position: static;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
  }
}

.ce-toolbox--opened {
  opacity: 1;
  visibility: visible;
}

.ce-toolbox__button {
  color: #707684;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ce-toolbox__button--active,
.ce-toolbox__button:hover {
  color: #388ae5;
}

.ce-toolbox__button--active {
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-toolbox-button-tooltip__shortcut {
  opacity: 0.6;
  word-spacing: -3px;
  margin-top: 3px;
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbox {
    background: #fff;
    z-index: 2;
  }
}

.ce-inline-toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-inline-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-inline-toolbar {
  -webkit-transform: translateX(-50%) translateY(8px) scale(0.9);
  transform: translateX(-50%) translateY(8px) scale(0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.25s ease, -webkit-transform 0.15s ease;
  transition: opacity 0.25s ease, -webkit-transform 0.15s ease;
  transition: transform 0.15s ease, opacity 0.25s ease;
  transition: transform 0.15s ease, opacity 0.25s ease,
    -webkit-transform 0.15s ease;
  will-change: transform, opacity;
  top: 0;
  left: 0;
}

.ce-inline-toolbar--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-inline-toolbar--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

.ce-inline-toolbar--showed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ce-inline-toolbar--left-oriented {
  -webkit-transform: translateX(-23px) translateY(8px) scale(0.9);
  transform: translateX(-23px) translateY(8px) scale(0.9);
}

.ce-inline-toolbar--left-oriented.ce-inline-toolbar--showed {
  -webkit-transform: translateX(-23px);
  transform: translateX(-23px);
}

.ce-inline-toolbar--right-oriented {
  -webkit-transform: translateX(-100%) translateY(8px) scale(0.9);
  transform: translateX(-100%) translateY(8px) scale(0.9);
  margin-left: 23px;
}

.ce-inline-toolbar--right-oriented.ce-inline-toolbar--showed {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.ce-inline-toolbar [hidden] {
  display: none !important;
}

.ce-inline-toolbar__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 6px;
}

.ce-inline-toolbar__dropdown {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 34px;
  padding: 0 9px 0 10px;
  margin: 0 6px 0 -6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid rgba(201, 201, 204, 0.48);
}

.ce-inline-toolbar__dropdown:hover {
  background: #eff2f5;
}

.ce-inline-toolbar__dropdown--hidden {
  display: none;
}

.ce-inline-toolbar__dropdown-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  font-size: 14px;
}

.ce-inline-toolbar__dropdown-content svg {
  height: 12px;
}

.ce-inline-toolbar__dropdown .icon--toggler-down {
  margin-left: 4px;
}

.ce-inline-toolbar__shortcut {
  opacity: 0.6;
  word-spacing: -3px;
  margin-top: 3px;
}

.ce-inline-tool {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.ce-inline-tool:hover {
  background-color: #eff2f5;
}

.ce-inline-tool {
  border-radius: 0;
  line-height: normal;
  width: auto;
  padding: 0 5px !important;
  min-width: 24px;
}

.ce-inline-tool--active {
  color: #388ae5;
}

.ce-inline-tool--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-inline-tool--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-inline-tool:not(:last-of-type) {
  margin-right: 2px;
}

.ce-inline-tool .icon {
  height: 12px;
}

.ce-inline-tool--last {
  margin-right: 0 !important;
}

.ce-inline-tool--link .icon--unlink,
.ce-inline-tool--unlink .icon--link {
  display: none;
}

.ce-inline-tool--unlink .icon--unlink {
  display: inline-block;
  margin-bottom: -1px;
}

.ce-inline-tool-input {
  outline: none;
  border: 0;
  border-radius: 0 0 4px 4px;
  margin: 0;
  font-size: 13px;
  padding: 10px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  font-weight: 500;
  border-top: 1px solid rgba(201, 201, 204, 0.48);
}

.ce-inline-tool-input::-webkit-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::-moz-placeholder {
  color: #707684;
}

.ce-inline-tool-input:-ms-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::-ms-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::placeholder {
  color: #707684;
}

.ce-inline-tool-input--showed {
  display: block;
}

.ce-conversion-toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-conversion-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-conversion-toolbar {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
  -webkit-transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease,
    -webkit-transform 0.1s ease;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  left: -1px;
  width: 150px;
  margin-top: 5px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.ce-conversion-toolbar--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-conversion-toolbar--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

.ce-conversion-toolbar--showed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  transform: none;
}

.ce-conversion-toolbar [hidden] {
  display: none !important;
}

.ce-conversion-toolbar__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ce-conversion-toolbar__label {
  color: #707684;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  padding: 10px 10px 5px;
  text-transform: uppercase;
}

.ce-conversion-tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ce-conversion-tool--hidden {
  display: none;
}

.ce-conversion-tool--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-conversion-tool--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-conversion-tool:hover {
  background: #eff2f5;
}

.ce-conversion-tool__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(201, 201, 204, 0.48);
  border-radius: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  background: #fff;
}

.ce-conversion-tool__icon svg {
  width: 11px;
  height: 11px;
}

.ce-conversion-tool--last {
  margin-right: 0 !important;
}

.ce-conversion-tool--active {
  color: #388ae5 !important;
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-settings {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-settings {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-settings {
  right: -1px;
  top: 30px;
  min-width: 114px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.ce-settings--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-settings--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

@media (max-width: 650px) {
  .ce-settings {
    bottom: 40px;
    right: -11px;
    top: auto;
  }
}

.ce-settings:before {
  left: auto;
  right: 12px;
}

@media (max-width: 650px) {
  .ce-settings:before {
    bottom: -5px;
    top: auto;
  }
}

.ce-settings {
  display: none;
}

.ce-settings--opened {
  display: block;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-name: panelShowing;
  animation-name: panelShowing;
}

.ce-settings__plugin-zone:not(:empty) {
  padding: 3px 3px 0;
}

.ce-settings__default-zone:not(:empty) {
  padding: 3px;
}

.ce-settings__button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.ce-settings__button:hover {
  background-color: #eff2f5;
}

.ce-settings__button--active {
  color: #388ae5;
}

.ce-settings__button--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-settings__button--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-settings__button:not(:nth-child(3n + 3)) {
  margin-right: 3px;
}

.ce-settings__button:nth-child(n + 4) {
  margin-top: 3px;
}

.ce-settings__button {
  line-height: 32px;
}

.ce-settings__button--disabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}

.ce-settings__button--selected {
  color: #388ae5;
}

.ce-settings__button--delete {
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  will-change: background-color;
}

.ce-settings__button--delete .icon {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  will-change: transform;
}

.ce-settings__button--confirm {
  background-color: #e24a4a !important;
  color: #fff;
}

.ce-settings__button--confirm:hover {
  background-color: #d54a4a !important;
}

.ce-settings__button--confirm .icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ce-block:first-of-type {
  margin-top: 0;
}

.ce-block--selected .ce-block__content {
  background: #e1f2ff;
}

.ce-block--selected .ce-block__content [contenteditable] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ce-block--selected .ce-block__content .ce-stub,
.ce-block--selected .ce-block__content img {
  opacity: 0.55;
}

.ce-block--stretched .ce-block__content {
  max-width: none;
}

.ce-block__content {
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}

.ce-block--drop-target .ce-block__content:before {
  content: "";
  position: absolute;
  top: 100%;
  left: -20px;
  margin-top: -1px;
  height: 8px;
  width: 8px;
  border: solid #388ae5;
  border-width: 1px 1px 0 0;
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ce-block--drop-target .ce-block__content:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 1px;
  width: 100%;
  color: #388ae5;
  background: repeating-linear-gradient(90deg,
      #388ae5,
      #388ae5 1px,
      #fff 0,
      #fff 6px);
}

.ce-block a {
  cursor: pointer;
  text-decoration: underline;
}

.ce-block b {
  font-weight: 700;
}

.ce-block i {
  font-style: italic;
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-block--focused {
    margin-right: -50px;
    padding-right: 50px;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-5deg);
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(3deg);
    transform: translate3d(2%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate(-3deg);
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(2deg);
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate(-1deg);
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-5deg);
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(3deg);
    transform: translate3d(2%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate(-3deg);
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(2deg);
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate(-1deg);
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  20% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  60% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  20% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  60% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes selectionBounce {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  70% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes selectionBounce {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  70% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes buttonClicked {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  60% {
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  80% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes buttonClicked {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  60% {
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  80% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes panelShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes panelShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cdx-block {
  padding: 0.4em 0;
}

.cdx-input {
  border: 1px solid rgba(201, 201, 204, 0.48);
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  border-radius: 3px;
  padding: 10px 12px;
  outline: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cdx-input[data-placeholder]:before {
  position: static !important;
  display: inline-block;
  width: 0;
  white-space: nowrap;
  pointer-events: none;
}

.cdx-settings-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.cdx-settings-button:hover {
  background-color: #eff2f5;
}

.cdx-settings-button--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.cdx-settings-button--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.cdx-settings-button:not(:nth-child(3n + 3)) {
  margin-right: 3px;
}

.cdx-settings-button:nth-child(n + 4) {
  margin-top: 3px;
}

.cdx-settings-button--active {
  color: #388ae5;
}

.cdx-loader {
  position: relative;
  border: 1px solid rgba(201, 201, 204, 0.48);
}

.cdx-loader:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -11px 0 0 -11px;
  border: 2px solid rgba(201, 201, 204, 0.48);
  border-left-color: #388ae5;
  border-radius: 50%;
  -webkit-animation: cdxRotation 1.2s linear infinite;
  animation: cdxRotation 1.2s linear infinite;
}

@-webkit-keyframes cdxRotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes cdxRotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.cdx-button {
  padding: 13px;
  border-radius: 3px;
  border: 1px solid rgba(201, 201, 204, 0.48);
  font-size: 14.9px;
  background: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  color: #707684;
  text-align: center;
  cursor: pointer;
}

.cdx-button:hover {
  background: #fbfcfe;
  -webkit-box-shadow: 0 1px 3px 0 rgba(18, 30, 57, 0.08);
  box-shadow: 0 1px 3px 0 rgba(18, 30, 57, 0.08);
}

.cdx-button svg {
  height: 20px;
  margin-right: 0.2em;
  margin-top: -2px;
}

.ce-stub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 3.5em 0;
  margin: 17px 0;
  border-radius: 3px;
  background: #fcf7f7;
  color: #b46262;
}

.ce-stub__info {
  margin-left: 20px;
}

.ce-stub__title {
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}

.ce-stub__subtitle {
  font-size: 16px;
}

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.cdx-notify--error {
  background: #fffbfb !important;
}

.cdx-notify--error::before {
  background: #fb5d5d !important;
}

.cdx-notify__input {
  max-width: 130px;
  padding: 5px 10px;
  background: #f7f7f7;
  border: 0;
  border-radius: 3px;
  font-size: 13px;
  color: #656b7c;
  outline: 0;
}

.cdx-notify__input:-ms-input-placeholder {
  color: #656b7c;
}

.cdx-notify__input::placeholder {
  color: #656b7c;
}

.cdx-notify__input:focus:-ms-input-placeholder {
  color: rgba(101, 107, 124, 0.3);
}

.cdx-notify__input:focus::placeholder {
  color: rgba(101, 107, 124, 0.3);
}

.cdx-notify__button {
  border: none;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
}

.cdx-notify__button:last-child {
  margin-left: 10px;
}

.cdx-notify__button--cancel {
  background: #f2f5f7;
  box-shadow: 0 2px 1px 0 rgba(16, 19, 29, 0);
  color: #656b7c;
}

.cdx-notify__button--cancel:hover {
  background: #eee;
}

.cdx-notify__button--confirm {
  background: #34c992;
  box-shadow: 0 1px 1px 0 rgba(18, 49, 35, 0.05);
  color: #fff;
}

.cdx-notify__button--confirm:hover {
  background: #33b082;
}

.cdx-notify__btns-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-top: 5px;
}

.cdx-notify__cross {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  padding: 5px;
  opacity: 0.54;
  cursor: pointer;
}

.cdx-notify__cross::after,
.cdx-notify__cross::before {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  height: 12px;
  width: 2px;
  background: #575d67;
}

.cdx-notify__cross::before {
  transform: rotate(-45deg);
}

.cdx-notify__cross::after {
  transform: rotate(45deg);
}

.cdx-notify__cross:hover {
  opacity: 1;
}

.cdx-notifies {
  position: fixed;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.cdx-notify {
  position: relative;
  width: 220px;
  margin-top: 15px;
  padding: 13px 16px;
  background: #fff;
  box-shadow: 0 11px 17px 0 rgba(23, 32, 61, 0.13);
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.4em;
  word-wrap: break-word;
}

.cdx-notify::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 3px;
  height: calc(100% - 6px);
  margin: 3px;
  border-radius: 5px;
  background: 0 0;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.cdx-notify--bounce-in {
  animation-name: bounceIn;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

.cdx-notify--success {
  background: #fafffe !important;
}

.cdx-notify--success::before {
  background: #41ffb1 !important;
}

.tc-editor {
  padding: 10px;
  position: relative;
  box-sizing: content-box;
  width: 100%;
  left: -10px;
}

.tc-toolbar {
  background: #368be6;
}

.tc-toolbar--hidden {
  visibility: hidden;
}

.tc-toolbar--hor {
  width: 100%;
  height: 21px;
  flex-direction: row;
  left: 0;
  top: 1px;
}

.tc-toolbar--hor,
.tc-toolbar--ver {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 100;
}

.tc-toolbar--ver {
  height: 100%;
  width: 21px;
  flex-direction: column;
  top: 0;
  left: -1px;
}

.tc-toolbar__plus {
  height: 21px;
  width: 21px;
  margin-top: 0;
}

.tc-toolbar__plus--hor {
  margin-left: -10px;
}

.tc-toolbar__plus--ver {
  margin-top: -10px;
}

.tc-toolbar__shine-line--hor {
  min-height: 1px;
  width: 100%;
}

.tc-toolbar__shine-line--ver {
  min-width: 1px;
  height: 100%;
}

.tc-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tc-table__wrap {
  border: 1px solid #dbdbe2;
  border-radius: 3px;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.tc-table__cell {
  border: 1px solid #dbdbe2;
  padding: 0;
  vertical-align: top;
}

.tc-table__area {
  padding: 10px;
  height: 100%;
}

.tc-table__inp {
  outline: none;
  flex-grow: 100;
  min-height: 1.5em;
  height: 100%;
  overflow: hidden;
}

.tc-table tbody tr:first-child td {
  border-top: none;
}

.tc-table tbody tr:last-child td {
  border-bottom: none;
}

.tc-table tbody tr td:last-child {
  border-right: none;
}

.tc-table tbody tr td:first-child {
  border-left: none;
}

.image-tool {
  --bg-color: #cdd1e0;
  --front-color: #388ae5;
  --border-color: #e8e8eb;
}

.image-tool__image {
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
}

.image-tool__image-picture {
  max-width: 100%;
  vertical-align: bottom;
  display: block;
}

.image-tool__image-preloader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  margin: auto;
  position: relative;
  background-color: var(--bg-color);
  background-position: center center;
}

.image-tool__image-preloader::after {
  content: "";
  position: absolute;
  z-index: 3;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--bg-color);
  border-top-color: var(--front-color);
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation: image-preloader-spin 2s infinite linear;
  box-sizing: border-box;
}

.image-tool__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute !important;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.image-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.image-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.image-tool--empty .image-tool__image {
  display: none;
}

.image-tool--empty .image-tool__caption,
.image-tool--loading .image-tool__caption {
  display: none;
}

.image-tool--filled .cdx-button {
  display: none;
}

.image-tool--filled .image-tool__image-preloader {
  display: none;
}

.image-tool--loading .image-tool__image {
  min-height: 200px;
  display: flex;
  border: 1px solid var(--border-color);
  background-color: #fff;
}

.image-tool--loading .image-tool__image-picture {
  display: none;
}

.image-tool--loading .cdx-button {
  display: none;
}

/**
   * Tunes
   * ----------------
   */

.image-tool--withBorder .image-tool__image {
  border: 1px solid var(--border-color);
}

.image-tool--withBackground .image-tool__image {
  padding: 15px;
  background: var(--bg-color);
}

.image-tool--withBackground .image-tool__image-picture {
  max-width: 60%;
  margin: 0 auto;
}

.image-tool--stretched .image-tool__image-picture {
  width: 100%;
}

@keyframes image-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.link-tool {
  position: relative;
}

.link-tool__input {
  padding-left: 38px;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgba(0, 0, 0, 0.6)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link-tool__input-holder {
  position: relative;
}

.link-tool__input-holder--error .link-tool__input {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgb(224, 147, 147)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A");
  background-color: #fff3f6;
  border-color: #f3e0e0;
  color: #a95a5a;
  box-shadow: inset 0 1px 3px 0 rgba(146, 62, 62, 0.05);
}

.link-tool__input[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.link-tool__input[contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.link-tool__input[contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.link-tool__progress {
  position: absolute;
  box-shadow: inset 0 1px 3px 0 rgba(102, 85, 107, 0.04);
  height: 100%;
  width: 0;
  background-color: #f4f5f7;
  z-index: -1;
}

.link-tool__progress--loading {
  -webkit-animation: progress 500ms ease-in;
  -webkit-animation-fill-mode: forwards;
}

.link-tool__progress--loaded {
  width: 100%;
}

.link-tool__content {
  display: block;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #fff;
  color: initial !important;
  text-decoration: none !important;
}

.link-tool__content::after {
  content: "";
  clear: both;
  display: table;
}

.link-tool__content--rendered {
  background: #fff;
  border: 1px solid rgba(201, 201, 204, 0.48);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  will-change: filter;
  animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
}

.link-tool__content--rendered:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}

.link-tool__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 0 30px;
  width: 65px;
  height: 65px;
  border-radius: 3px;
  float: right;
}

.link-tool__title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5em;
  margin: 0 0 10px 0;
}

.link-tool__title+.link-tool__anchor {
  margin-top: 25px;
}

.link-tool__description {
  margin: 0 0 20px 0;
  font-size: 15px;
  line-height: 1.55em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

span.link-tool__anchor {
  display: block;
  font-size: 15px;
  line-height: 1em;
  color: #888 !important;
  border: 0 !important;
  padding: 0 !important;
}

small.link-tool__anchor {
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: #888 !important;
  border: 0 !important;
  padding: 0 !important;
}

@keyframes link-in {
  from {
    filter: blur(5px);
  }

  to {
    filter: none;
  }
}

.codex-editor--narrow .link-tool__image {
  display: none;
}

@-webkit-keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 85%;
  }
}

.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__text {
  min-height: 158px;
  margin-bottom: 10px;
}

.cdx-quote__caption {}

.cdx-quote [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-quote [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-quote [contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}

.cdx-list {
  margin: 0;
  padding-left: 40px;
  outline: none;
}

.cdx-list__item {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
}

.cdx-list--unordered {
  list-style: disc;
}

.cdx-list--ordered {
  list-style: decimal;
}

.cdx-list-settings {
  display: flex;
}

.cdx-list-settings .cdx-settings-button {
  width: 50%;
}

.embed-tool--loading .embed-tool__caption {
  display: none;
}

.embed-tool--loading .embed-tool__preloader {
  display: block;
}

.embed-tool--loading .embed-tool__content {
  display: none;
}

.embed-tool__preloader {
  display: none;
  position: relative;
  height: 200px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e6e9eb;
}

.embed-tool__preloader::before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid #cdd1e0;
  border-top-color: #388ae5;
  box-sizing: border-box;
  animation: embed-preloader-spin 2s infinite linear;
}

.embed-tool__url {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 250px;
  color: #7b7e89;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.embed-tool__content {
  width: 100%;
}

.embed-tool__caption {
  margin-top: 7px;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

@keyframes embed-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cdx-warning {
  position: relative;
}

.cdx-warning [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-warning [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-warning [contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-warning::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 320 294' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%237B7E89' d='M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z'/%3E%3C/svg%3E");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  position: absolute;
  margin-top: 12px;
  left: -30px;
}

@media all and (max-width: 735px) {
  .cdx-warning::before {
    display: none;
  }
}

.cdx-warning__message {
  min-height: 85px;
}

.cdx-warning__title {
  margin-bottom: 6px;
}

/**
 * Plugin styles
 */
.ce-header {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.ce-header p,
.ce-header div {
  padding: 0 !important;
  margin: 0 !important;
}

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {}

.ce-header[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.cdx-checklist__item {
  display: flex;
  padding: 0 10px;
  box-sizing: content-box;
}

.cdx-checklist__item-text {
  outline: none;
  flex-grow: 1;
  padding: 10px 0;
}

.cdx-checklist__item-checkbox {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background: #fff;
  cursor: pointer;
  user-select: none;
}

.cdx-checklist__item-checkbox-display {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background: #fff;
  user-select: none;
}

.cdx-checklist__item-checkbox:hover {
  border-color: #b5b5b5;
}

.cdx-checklist__item-checkbox::after,
.cdx-checklist__item-checkbox-display::after {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 5px;
  border: 2px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  content: "";
  opacity: 0;
  transform: rotate(-45deg);
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox {
  background: var(--success) !important;
  border-color: var(--success) !important;
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox:hover,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display:hover {
  background: #307cd1;
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox::after,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display::after {
  opacity: 1;
}

/**
 * Plugin styles
 */
.ce-header {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.ce-header p,
.ce-header div {
  padding: 0 !important;
  margin: 0 !important;
}

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {}

.ce-header[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.cdx-marker {
  background: rgba(245, 235, 111, 0.29);
  padding: 3px 0;
}

.cdx-underline {
  text-decoration: underline;
}

.ce-rawtool__textarea {
  min-height: 200px;
  resize: vertical;
  border-radius: 5px;
  background-color: #1e2128;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #9ca3b3;
}

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.ce-delimiter {
  line-height: 1.6em;
  width: 100%;
  text-align: center;
}

.ce-delimiter:before {
  display: inline-block;
  content: "***";
  font-size: 30px;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}

body:not(.disabled) .turn-chevron:hover>.fas {
  transform: rotate(-180deg);
}

.turn-chevron>.fas {
  transition: all .2s ease;
}

.category.has-children .category-text::after {
  content: "\f078";
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  color: #000;
  transition: all .2s ease;
  font-family: 'Font Awesome 5 Pro';
}

.category.active .category-text::before {
  content: "\f058";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 600;
  color: var(--success);
  margin-right: 10px;
}

.category:not(.active):hover .category-text::before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 800;
  font-size: 11px;
  color: var(--primary);
  margin-right: 8px;
  margin-left: 2px;
}

.category.has-children:hover .category-text::after {
  transform: rotate(-180deg) translateY(1px);
}

.sub-category-container {
  border: 1px solid rgb(0, 0, 0, .1);
  /* padding: 20px 0 0px 20px !important; */
}

.category-container,
.sub-category-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 0 25px 0;
  overflow-y: overlay;
  /* background-color: #f5f5f5; */
  background-color: #fff;
  overflow-x: hidden;
  scroll-snap-type: block;
  scroll-behavior: smooth;
}

.sub-category-container {
  /* opacity: 0; */
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  top: 0;
  white-space: nowrap;
  right: 100%;
  transition: all 0.2s ease;
  width: 0;
  float: right;
}

.sub-category-container.active {
  display: flex;
  width: 100%;
  transform: translateY(0);
  visibility: visible;
  /* opacity: 1; */
}

.theres-more-down-below-captain,
.theres-more-up-top-captain {
  position: absolute;
  bottom: 30px;
  right: 15px;
  width: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 25px;
  /* transform: translate(-50%); */
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  z-index: 1034;
}

.theres-more-up-top-captain {
  bottom: unset;
  top: 12px;
}

.category-container .category,
.sub-category-container .category {
  position: relative;
  /* margin: 0 5px; */
  justify-content: space-between;
  display: flex;
  transition: all 0.2s ease;
  padding: 0 15px;
  height: 50px;
  border-bottom: 1px solid #e6e6e6;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-margin-top: 1px;
}

.breadcrumb a {
  padding-left: unset !important;
}

.category-container .category.active,
.category-container .category:hover,
.sub-category-container .category.active,
.sub-category-container .category:hover {
  /* background-color: #e6e6e6; */
  color: #111;
  cursor: pointer;
  /* transform: scale(1.05, 1.05); */
}

.product-load-count .productShowCount {
  color: var(--warning);
}

.solbris-open-lg {
  width: 500px;
  border: solid #ccc 1px;
  -webkit-box-shadow: 0px 2px 49px 6px rgba(150, 150, 150, 1);
  -moz-box-shadow: 0px 2px 49px 6px rgba(150, 150, 150, 1);
  box-shadow: 0px 2px 49px 6px rgba(150, 150, 150, 1);
}

.product-load-count {
  display: block;
  position: absolute;
  top: 20px;
  left: 50%;
  font-weight: bold;
  transform: translate(-50%);
  font-size: 16px;
}

.filter-box {
  height: calc(50vh - 40px);
}

.category-container .category .mdi,
.sub-category-container .category .mdi {
  font-size: 24px;
  margin-top: 6px;
}

@media (max-width: 997px) {

  .category-container .category .mdi {
    margin-top: -12px !important;
  }

  .sub-category-container .category .mdi {
    margin-top: -16px !important;
  }

  .front-title {
    bottom: 15px;
  }
}

@media (max-width: 1195px) {

  .category-container .category,
  .sub-category-container .category {
    padding: 0 15px;
  }
}

@media (max-width: 997px) {
  .filter-box {
    height: 55vh !important;
  }

  .solbris-open .showFilteredProductsBtn {
    left: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1033;
    border-radius: 0 !important;
    font-weight: 800;
    background-color: #6f75b9 !important;
    height: 75px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .top-category-container.half {
    height: 100%;
  }

  .category-container.tab-100 {
    max-height: 100% !important;
    overflow: unset !important;
  }

  .sub-category-container:not(.big) {
    left: 0;
  }

  .sub-category-container .category {
    height: 45px;
  }

  .sub-category-container.active:not(.big) {
    width: 100% !important;
  }
}

@media (max-width: 514px) {

  .category-container .category,
  .sub-category-container .category {
    padding: 15px 15px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.category-container .category .category-image,
.sub-category-container .category .category-image {
  height: 100%;
}

.category-container .category .category-text,
.sub-category-container .category .category-text {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0 !important;
  line-height: 18px;
  display: flex;
  align-self: center;
  text-align: left;
  margin: 0;
  margin-right: 5px;
}

.sticky {
  position: sticky;
  top: 0;
}

.half {
  height: 50vh;
}

/*######################################*/
/*               EditorJS               */
/*######################################*/
.ejs-content b {
  font-weight: 600;
}

.editor-quote {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.editor-quote p {
  font-size: 12px;
  color: var(--gray);
  position: absolute;
  bottom: -36px;
  right: 0;
}

.editor-quote pre {
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  white-space: break-spaces;
}

.codex-editor {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}

.codex-editor .hide,
.codex-editor__redactor--hidden {
  display: none;
}

.codex-editor__redactor [contenteditable]:empty:after {
  content: "\feff ";
}

@media (min-width: 651px) {
  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 50px;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbar__actions {
    right: -5px;
  }
}

.codex-editor__loader {
  position: relative;
  height: 30vh;
}

.codex-editor__loader:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid rgba(201, 201, 204, 0.48);
  border-top-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: editor-loader-spin 0.8s linear infinite;
  animation: editor-loader-spin 0.8s linear infinite;
  will-change: transform;
}

.codex-editor-copyable {
  position: absolute;
  height: 1px;
  width: 1px;
  top: -400%;
  opacity: 0.001;
}

.codex-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none;
  overflow: hidden;
}

.codex-editor-overlay__container {
  position: relative;
  pointer-events: auto;
  z-index: 0;
}

.codex-editor-overlay__rectangle {
  position: absolute;
  pointer-events: none;
  background-color: rgba(46, 170, 220, 0.2);
  border: 1px solid transparent;
}

.codex-editor svg {
  fill: currentColor;
  vertical-align: middle;
  max-height: 100%;
}

::-moz-selection {
  background-color: #d4ecff;
}

::selection {
  background-color: #d4ecff;
}

.codex-editor--toolbox-opened [contentEditable="true"][data-placeholder]:focus:before {
  opacity: 0 !important;
}

@-webkit-keyframes editor-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes editor-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.ce-toolbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  will-change: opacity, transform;
  display: none;
}

@media (max-width: 650px) {
  .ce-toolbar {
    position: absolute;
    background-color: #fff;
    border: 1px solid #eaeaea;
    -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    border-radius: 4px;
    z-index: 2;
  }
}

@media (max-width: 650px) and (max-width: 650px) {
  .ce-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

@media (max-width: 650px) {
  .ce-toolbar {
    padding: 3px;
    margin-top: 5px;
  }

  .ce-toolbar--left-oriented:before {
    left: 15px;
    margin-left: 0;
  }

  .ce-toolbar--right-oriented:before {
    left: auto;
    right: 15px;
    margin-left: 0;
  }
}

.ce-toolbar--opened {
  display: block;
}

@media (max-width: 650px) {
  .ce-toolbar--opened {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.ce-toolbar__content {
  max-width: 650px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 650px) {
  .ce-toolbar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 0;
    max-width: calc(100% - 35px);
  }
}

.ce-toolbar__plus {
  color: #707684;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: -34px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ce-toolbar__plus--active,
.ce-toolbar__plus:hover {
  color: #388ae5;
}

.ce-toolbar__plus--active {
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-toolbar__plus-shortcut {
  opacity: 0.6;
  word-spacing: -2px;
  margin-top: 5px;
}

.ce-toolbar__plus--hidden {
  display: none;
}

@media (max-width: 650px) {
  .ce-toolbar__plus {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    position: static;
    -webkit-transform: none !important;
    transform: none !important;
  }
}

.ce-toolbar .ce-toolbox,
.ce-toolbar__plus {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ce-toolbar__actions {
  position: absolute;
  right: -30px;
  top: 5px;
  opacity: 0;
}

@media (max-width: 650px) {
  .ce-toolbar__actions {
    position: absolute;
    right: -28px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.ce-toolbar__actions--opened {
  opacity: 1;
}

.ce-toolbar__actions-buttons {
  text-align: right;
}

.ce-toolbar__settings-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: #707684;
  cursor: pointer;
  background: #eff2f5;
}

.ce-toolbar__settings-btn:hover {
  color: #1d202b;
}

@media (max-width: 650px) {
  .ce-toolbar__settings-btn {
    background: transparent;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbar__plus {
    left: 5px;
  }
}

.ce-toolbox {
  position: absolute;
  visibility: hidden;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  will-change: opacity;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 650px) {
  .ce-toolbox {
    position: static;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
  }
}

.ce-toolbox--opened {
  opacity: 1;
  visibility: visible;
}

.ce-toolbox__button {
  color: #707684;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.ce-toolbox__button--active,
.ce-toolbox__button:hover {
  color: #388ae5;
}

.ce-toolbox__button--active {
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-toolbox-button-tooltip__shortcut {
  opacity: 0.6;
  word-spacing: -3px;
  margin-top: 3px;
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbox {
    background: #fff;
    z-index: 2;
  }
}

.ce-inline-toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-inline-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-inline-toolbar {
  -webkit-transform: translateX(-50%) translateY(8px) scale(0.9);
  transform: translateX(-50%) translateY(8px) scale(0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.25s ease, -webkit-transform 0.15s ease;
  transition: opacity 0.25s ease, -webkit-transform 0.15s ease;
  transition: transform 0.15s ease, opacity 0.25s ease;
  transition: transform 0.15s ease, opacity 0.25s ease,
    -webkit-transform 0.15s ease;
  will-change: transform, opacity;
  top: 0;
  left: 0;
}

.ce-inline-toolbar--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-inline-toolbar--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

.ce-inline-toolbar--showed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ce-inline-toolbar--left-oriented {
  -webkit-transform: translateX(-23px) translateY(8px) scale(0.9);
  transform: translateX(-23px) translateY(8px) scale(0.9);
}

.ce-inline-toolbar--left-oriented.ce-inline-toolbar--showed {
  -webkit-transform: translateX(-23px);
  transform: translateX(-23px);
}

.ce-inline-toolbar--right-oriented {
  -webkit-transform: translateX(-100%) translateY(8px) scale(0.9);
  transform: translateX(-100%) translateY(8px) scale(0.9);
  margin-left: 23px;
}

.ce-inline-toolbar--right-oriented.ce-inline-toolbar--showed {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.ce-inline-toolbar [hidden] {
  display: none !important;
}

.ce-inline-toolbar__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 6px;
}

.ce-inline-toolbar__dropdown {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 34px;
  padding: 0 9px 0 10px;
  margin: 0 6px 0 -6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid rgba(201, 201, 204, 0.48);
}

.ce-inline-toolbar__dropdown:hover {
  background: #eff2f5;
}

.ce-inline-toolbar__dropdown--hidden {
  display: none;
}

.ce-inline-toolbar__dropdown-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  font-size: 14px;
}

.ce-inline-toolbar__dropdown-content svg {
  height: 12px;
}

.ce-inline-toolbar__dropdown .icon--toggler-down {
  margin-left: 4px;
}

.ce-inline-toolbar__shortcut {
  opacity: 0.6;
  word-spacing: -3px;
  margin-top: 3px;
}

.ce-inline-tool {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.ce-inline-tool:hover {
  background-color: #eff2f5;
}

.ce-inline-tool {
  border-radius: 0;
  line-height: normal;
  width: auto;
  padding: 0 5px !important;
  min-width: 24px;
}

.ce-inline-tool--active {
  color: #388ae5;
}

.ce-inline-tool--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-inline-tool--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-inline-tool:not(:last-of-type) {
  margin-right: 2px;
}

.ce-inline-tool .icon {
  height: 12px;
}

.ce-inline-tool--last {
  margin-right: 0 !important;
}

.ce-inline-tool--link .icon--unlink,
.ce-inline-tool--unlink .icon--link {
  display: none;
}

.ce-inline-tool--unlink .icon--unlink {
  display: inline-block;
  margin-bottom: -1px;
}

.ce-inline-tool-input {
  outline: none;
  border: 0;
  border-radius: 0 0 4px 4px;
  margin: 0;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  font-weight: 500;
  border-top: 1px solid rgba(201, 201, 204, 0.48);
}

.ce-inline-tool-input::-webkit-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::-moz-placeholder {
  color: #707684;
}

.ce-inline-tool-input:-ms-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::-ms-input-placeholder {
  color: #707684;
}

.ce-inline-tool-input::placeholder {
  color: #707684;
}

.ce-inline-tool-input--showed {
  display: block;
}

.ce-conversion-toolbar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-conversion-toolbar {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-conversion-toolbar {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
  -webkit-transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease,
    -webkit-transform 0.1s ease;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  left: -1px;
  width: 150px;
  margin-top: 5px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.ce-conversion-toolbar--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-conversion-toolbar--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

.ce-conversion-toolbar--showed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  transform: none;
}

.ce-conversion-toolbar [hidden] {
  display: none !important;
}

.ce-conversion-toolbar__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ce-conversion-toolbar__label {
  color: #707684;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  padding: 10px 10px 5px;
  text-transform: uppercase;
}

.ce-conversion-tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ce-conversion-tool--hidden {
  display: none;
}

.ce-conversion-tool--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-conversion-tool--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-conversion-tool:hover {
  background: #eff2f5;
}

.ce-conversion-tool__icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(201, 201, 204, 0.48);
  border-radius: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  background: #fff;
}

.ce-conversion-tool__icon svg {
  width: 11px;
  height: 11px;
}

.ce-conversion-tool--last {
  margin-right: 0 !important;
}

.ce-conversion-tool--active {
  color: #388ae5 !important;
  -webkit-animation: bounceIn 0.75s 1;
  animation: bounceIn 0.75s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ce-settings {
  position: absolute;
  background-color: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 4px;
  z-index: 2;
}

@media (max-width: 650px) {
  .ce-settings {
    -webkit-box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    box-shadow: 0 13px 7px -5px rgba(26, 38, 49, 0.09),
      6px 15px 34px -6px rgba(33, 48, 73, 0.29);
    border-bottom-color: #d5d7db;
  }
}

.ce-settings {
  right: -1px;
  top: 30px;
  min-width: 114px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.ce-settings--left-oriented:before {
  left: 15px;
  margin-left: 0;
}

.ce-settings--right-oriented:before {
  left: auto;
  right: 15px;
  margin-left: 0;
}

@media (max-width: 650px) {
  .ce-settings {
    bottom: 40px;
    right: -11px;
    top: auto;
  }
}

.ce-settings:before {
  left: auto;
  right: 12px;
}

@media (max-width: 650px) {
  .ce-settings:before {
    bottom: -5px;
    top: auto;
  }
}

.ce-settings {
  display: none;
}

.ce-settings--opened {
  display: block;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-name: panelShowing;
  animation-name: panelShowing;
}

.ce-settings__plugin-zone:not(:empty) {
  padding: 3px 3px 0;
}

.ce-settings__default-zone:not(:empty) {
  padding: 3px;
}

.ce-settings__button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.ce-settings__button:hover {
  background-color: #eff2f5;
}

.ce-settings__button--active {
  color: #388ae5;
}

.ce-settings__button--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.ce-settings__button--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.ce-settings__button:not(:nth-child(3n + 3)) {
  margin-right: 3px;
}

.ce-settings__button:nth-child(n + 4) {
  margin-top: 3px;
}

.ce-settings__button {
  line-height: 32px;
}

.ce-settings__button--disabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}

.ce-settings__button--selected {
  color: #388ae5;
}

.ce-settings__button--delete {
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  will-change: background-color;
}

.ce-settings__button--delete .icon {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  will-change: transform;
}

.ce-settings__button--confirm {
  background-color: #e24a4a !important;
  color: #fff;
}

.ce-settings__button--confirm:hover {
  background-color: #d54a4a !important;
}

.ce-settings__button--confirm .icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ce-block:first-of-type {
  margin-top: 0;
}

.ce-block--selected .ce-block__content {
  background: #e1f2ff;
}

.ce-block--selected .ce-block__content [contenteditable] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ce-block--selected .ce-block__content .ce-stub,
.ce-block--selected .ce-block__content img {
  opacity: 0.55;
}

.ce-block--stretched .ce-block__content {
  max-width: none;
}

.ce-block__content {
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}

.ce-block--drop-target .ce-block__content:before {
  content: "";
  position: absolute;
  top: 100%;
  left: -20px;
  margin-top: -1px;
  height: 8px;
  width: 8px;
  border: solid #388ae5;
  border-width: 1px 1px 0 0;
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ce-block--drop-target .ce-block__content:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 1px;
  width: 100%;
  color: #388ae5;
  background: repeating-linear-gradient(90deg,
      #388ae5,
      #388ae5 1px,
      #fff 0,
      #fff 6px);
}

.ce-block a {
  cursor: pointer;
  text-decoration: underline;
}

.ce-block b {
  font-weight: 700;
}

.ce-block i {
  font-style: italic;
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-block--focused {
    margin-right: -50px;
    padding-right: 50px;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-5deg);
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(3deg);
    transform: translate3d(2%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate(-3deg);
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(2deg);
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate(-1deg);
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-5deg);
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(3deg);
    transform: translate3d(2%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate(-3deg);
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(2%, 0, 0) rotate(2deg);
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate(-1deg);
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  20% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  60% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  20% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  60% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes selectionBounce {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  70% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes selectionBounce {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  50% {
    -webkit-transform: scale3d(1.01, 1.01, 1.01);
    transform: scale3d(1.01, 1.01, 1.01);
  }

  70% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes buttonClicked {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  60% {
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  80% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes buttonClicked {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  60% {
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  80% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@-webkit-keyframes panelShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes panelShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cdx-block {
  padding: 0.4em 0;
}

.cdx-input {
  border: 1px solid rgba(201, 201, 204, 0.48);
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  border-radius: 3px;
  padding: 10px 12px;
  outline: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.cdx-input[data-placeholder]:before {
  position: static !important;
  display: inline-block;
  width: 0;
  white-space: nowrap;
  pointer-events: none;
}

.cdx-settings-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0 !important;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  vertical-align: bottom;
  color: #000;
  margin: 0;
}

.cdx-settings-button:hover {
  background-color: #eff2f5;
}

.cdx-settings-button--focused {
  -webkit-box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  box-shadow: inset 0 0 0 1px rgba(7, 161, 227, 0.08);
  background: rgba(34, 186, 255, 0.08) !important;
}

.cdx-settings-button--focused-animated {
  -webkit-animation-name: buttonClicked;
  animation-name: buttonClicked;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

.cdx-settings-button:not(:nth-child(3n + 3)) {
  margin-right: 3px;
}

.cdx-settings-button:nth-child(n + 4) {
  margin-top: 3px;
}

.cdx-settings-button--active {
  color: #388ae5;
}

.cdx-loader {
  position: relative;
  border: 1px solid rgba(201, 201, 204, 0.48);
}

.cdx-loader:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -11px 0 0 -11px;
  border: 2px solid rgba(201, 201, 204, 0.48);
  border-left-color: #388ae5;
  border-radius: 50%;
  -webkit-animation: cdxRotation 1.2s linear infinite;
  animation: cdxRotation 1.2s linear infinite;
}

@-webkit-keyframes cdxRotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes cdxRotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.cdx-button {
  padding: 13px;
  border-radius: 3px;
  border: 1px solid rgba(201, 201, 204, 0.48);
  font-size: 14.9px;
  background: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  color: #707684;
  text-align: center;
  cursor: pointer;
}

.cdx-button:hover {
  background: #fbfcfe;
  -webkit-box-shadow: 0 1px 3px 0 rgba(18, 30, 57, 0.08);
  box-shadow: 0 1px 3px 0 rgba(18, 30, 57, 0.08);
}

.cdx-button svg {
  height: 20px;
  margin-right: 0.2em;
  margin-top: -2px;
}

.ce-stub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 3.5em 0;
  margin: 17px 0;
  border-radius: 3px;
  background: #fcf7f7;
  color: #b46262;
}

.ce-stub__info {
  margin-left: 20px;
}

.ce-stub__title {
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
}

.ce-stub__subtitle {
  font-size: 16px;
}

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.cdx-notify--error {
  background: #fffbfb !important;
}

.cdx-notify--error::before {
  background: #fb5d5d !important;
}

.cdx-notify__input {
  max-width: 130px;
  padding: 5px 10px;
  background: #f7f7f7;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  color: #656b7c;
  outline: 0;
}

.cdx-notify__input:-ms-input-placeholder {
  color: #656b7c;
}

.cdx-notify__input::placeholder {
  color: #656b7c;
}

.cdx-notify__input:focus:-ms-input-placeholder {
  color: rgba(101, 107, 124, 0.3);
}

.cdx-notify__input:focus::placeholder {
  color: rgba(101, 107, 124, 0.3);
}

.cdx-notify__button {
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
}

.cdx-notify__button:last-child {
  margin-left: 10px;
}

.cdx-notify__button--cancel {
  background: #f2f5f7;
  box-shadow: 0 2px 1px 0 rgba(16, 19, 29, 0);
  color: #656b7c;
}

.cdx-notify__button--cancel:hover {
  background: #eee;
}

.cdx-notify__button--confirm {
  background: #34c992;
  box-shadow: 0 1px 1px 0 rgba(18, 49, 35, 0.05);
  color: #fff;
}

.cdx-notify__button--confirm:hover {
  background: #33b082;
}

.cdx-notify__btns-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-top: 5px;
}

.cdx-notify__cross {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  padding: 5px;
  opacity: 0.54;
  cursor: pointer;
}

.cdx-notify__cross::after,
.cdx-notify__cross::before {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  height: 12px;
  width: 2px;
  background: #575d67;
}

.cdx-notify__cross::before {
  transform: rotate(-45deg);
}

.cdx-notify__cross::after {
  transform: rotate(45deg);
}

.cdx-notify__cross:hover {
  opacity: 1;
}

.cdx-notifies {
  position: fixed;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.cdx-notify {
  position: relative;
  width: 220px;
  margin-top: 15px;
  padding: 13px 16px;
  background: #fff;
  box-shadow: 0 11px 17px 0 rgba(23, 32, 61, 0.13);
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.4em;
  word-wrap: break-word;
}

.cdx-notify::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 3px;
  height: calc(100% - 6px);
  margin: 3px;
  border-radius: 5px;
  background: 0 0;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.cdx-notify--bounce-in {
  animation-name: bounceIn;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

.cdx-notify--success {
  background: #fafffe !important;
}

.cdx-notify--success::before {
  background: #41ffb1 !important;
}

.tc-editor {
  padding: 10px;
  position: relative;
  box-sizing: content-box;
  width: 100%;
  left: -10px;
}

.tc-toolbar {
  background: #368be6;
}

.tc-toolbar--hidden {
  visibility: hidden;
}

.tc-toolbar--hor {
  width: 100%;
  height: 21px;
  flex-direction: row;
  left: 0;
  top: 1px;
}

.tc-toolbar--hor,
.tc-toolbar--ver {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 100;
}

.tc-toolbar--ver {
  height: 100%;
  width: 21px;
  flex-direction: column;
  top: 0;
  left: -1px;
}

.tc-toolbar__plus {
  height: 21px;
  width: 21px;
  margin-top: 0;
}

.tc-toolbar__plus--hor {
  margin-left: -10px;
}

.tc-toolbar__plus--ver {
  margin-top: -10px;
}

.tc-toolbar__shine-line--hor {
  min-height: 1px;
  width: 100%;
}

.tc-toolbar__shine-line--ver {
  min-width: 1px;
  height: 100%;
}

.tc-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tc-table__wrap {
  border: 1px solid #dbdbe2;
  border-radius: 3px;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.tc-table__cell {
  border: 1px solid #dbdbe2;
  padding: 0;
  vertical-align: top;
}

.tc-table__area {
  padding: 10px;
  height: 100%;
}

.tc-table__inp {
  outline: none;
  flex-grow: 100;
  min-height: 1.5em;
  height: 100%;
  overflow: hidden;
}

.tc-table tbody tr:first-child td {
  border-top: none;
}

.tc-table tbody tr:last-child td {
  border-bottom: none;
}

.tc-table tbody tr td:last-child {
  border-right: none;
}

.tc-table tbody tr td:first-child {
  border-left: none;
}

.image-tool {
  --bg-color: #cdd1e0;
  --front-color: #388ae5;
  --border-color: #e8e8eb;
}

.image-tool__image {
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
}

.image-tool__image-picture {
  max-width: 100%;
  vertical-align: bottom;
  display: block;
}

.image-tool__image-preloader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  margin: auto;
  position: relative;
  background-color: var(--bg-color);
  background-position: center center;
}

.image-tool__image-preloader::after {
  content: "";
  position: absolute;
  z-index: 3;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--bg-color);
  border-top-color: var(--front-color);
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation: image-preloader-spin 2s infinite linear;
  box-sizing: border-box;
}

.image-tool__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute !important;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.image-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.image-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.image-tool--empty .image-tool__image {
  display: none;
}

.image-tool--empty .image-tool__caption,
.image-tool--loading .image-tool__caption {
  display: none;
}

.image-tool--filled .cdx-button {
  display: none;
}

.image-tool--filled .image-tool__image-preloader {
  display: none;
}

.image-tool--loading .image-tool__image {
  min-height: 200px;
  display: flex;
  border: 1px solid var(--border-color);
  background-color: #fff;
}

.image-tool--loading .image-tool__image-picture {
  display: none;
}

.image-tool--loading .cdx-button {
  display: none;
}

/**
   * Tunes
   * ----------------
   */

.image-tool--withBorder .image-tool__image {
  border: 1px solid var(--border-color);
}

.image-tool--withBackground .image-tool__image {
  padding: 15px;
  background: var(--bg-color);
}

.image-tool--withBackground .image-tool__image-picture {
  max-width: 60%;
  margin: 0 auto;
}

.image-tool--stretched .image-tool__image-picture {
  width: 100%;
}

@keyframes image-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.link-tool {
  position: relative;
}

.link-tool__input {
  padding-left: 38px;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgba(0, 0, 0, 0.6)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link-tool__input-holder {
  position: relative;
}

.link-tool__input-holder--error .link-tool__input {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgb(224, 147, 147)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A");
  background-color: #fff3f6;
  border-color: #f3e0e0;
  color: #a95a5a;
  box-shadow: inset 0 1px 3px 0 rgba(146, 62, 62, 0.05);
}

.link-tool__input[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.link-tool__input[contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.link-tool__input[contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.link-tool__progress {
  position: absolute;
  box-shadow: inset 0 1px 3px 0 rgba(102, 85, 107, 0.04);
  height: 100%;
  width: 0;
  background-color: #f4f5f7;
  z-index: -1;
}

.link-tool__progress--loading {
  -webkit-animation: progress 500ms ease-in;
  -webkit-animation-fill-mode: forwards;
}

.link-tool__progress--loaded {
  width: 100%;
}

.link-tool__content {
  display: block;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #fff;
  color: initial !important;
  text-decoration: none !important;
}

.link-tool__content::after {
  content: "";
  clear: both;
  display: table;
}

.link-tool__content--rendered {
  background: #fff;
  border: 1px solid rgba(201, 201, 204, 0.48);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  will-change: filter;
  animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
}

.link-tool__content--rendered:hover {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}

.link-tool__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 0 30px;
  width: 65px;
  height: 65px;
  border-radius: 3px;
  float: right;
}

.link-tool__title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5em;
  margin: 0 0 10px 0;
}

.link-tool__title+.link-tool__anchor {
  margin-top: 25px;
}

.link-tool__description {
  margin: 0 0 20px 0;
  font-size: 15px;
  line-height: 1.55em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

span.link-tool__anchor {
  display: block;
  font-size: 15px;
  line-height: 1em;
  color: #888 !important;
  border: 0 !important;
  padding: 0 !important;
}

small.link-tool__anchor {
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: #888 !important;
  border: 0 !important;
  padding: 0 !important;
}

@keyframes link-in {
  from {
    filter: blur(5px);
  }

  to {
    filter: none;
  }
}

.codex-editor--narrow .link-tool__image {
  display: none;
}

@-webkit-keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 85%;
  }
}

.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__text {
  min-height: 158px;
  margin-bottom: 10px;
}

.cdx-quote__caption {}

.cdx-quote [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-quote [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-quote [contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}

.cdx-list {
  margin: 0;
  padding-left: 40px;
  outline: none;
}

.cdx-list__item {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
}

.cdx-list--unordered {
  list-style: disc;
}

.cdx-list--ordered {
  list-style: decimal;
}

.cdx-list-settings {
  display: flex;
}

.cdx-list-settings .cdx-settings-button {
  width: 50%;
}

.embed-tool--loading .embed-tool__caption {
  display: none;
}

.embed-tool--loading .embed-tool__preloader {
  display: block;
}

.embed-tool--loading .embed-tool__content {
  display: none;
}

.embed-tool__preloader {
  display: none;
  position: relative;
  height: 200px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e6e9eb;
}

.embed-tool__preloader::before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid #cdd1e0;
  border-top-color: #388ae5;
  box-sizing: border-box;
  animation: embed-preloader-spin 2s infinite linear;
}

.embed-tool__url {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 250px;
  color: #7b7e89;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.embed-tool__content {
  width: 100%;
}

.embed-tool__caption {
  margin-top: 7px;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.embed-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

@keyframes embed-preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cdx-warning {
  position: relative;
}

.cdx-warning [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-warning [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-warning [contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-warning::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 320 294' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%237B7E89' d='M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z'/%3E%3C/svg%3E");
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  position: absolute;
  margin-top: 12px;
  left: -30px;
}

@media all and (max-width: 735px) {
  .cdx-warning::before {
    display: none;
  }
}

.cdx-warning__message {
  min-height: 85px;
}

.cdx-warning__title {
  margin-bottom: 6px;
}

/**
 * Plugin styles
 */
.ce-header {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.ce-header p,
.ce-header div {
  padding: 0 !important;
  margin: 0 !important;
}

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {}

.ce-header[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.cdx-checklist__item {
  display: flex;
  padding: 0 10px;
  box-sizing: content-box;
}

.cdx-checklist__item-text {
  outline: none;
  flex-grow: 1;
  padding: 10px 0;
}

.cdx-checklist__item-checkbox {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background: #fff;
  cursor: pointer;
  user-select: none;
}

.cdx-checklist__item-checkbox-display {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 10px 10px 10px 0;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background: #fff;
  user-select: none;
}

.cdx-checklist__item-checkbox:hover {
  border-color: #b5b5b5;
}

.cdx-checklist__item-checkbox::after,
.cdx-checklist__item-checkbox-display::after {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 5px;
  border: 2px solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  content: "";
  opacity: 0;
  transform: rotate(-45deg);
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox {
  background: var(--success) !important;
  border-color: var(--success) !important;
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox:hover,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display:hover {
  background: #307cd1;
}

.cdx-checklist__item--checked .cdx-checklist__item-checkbox::after,
.cdx-checklist__item--checked .cdx-checklist__item-checkbox-display::after {
  opacity: 1;
}

/**
 * Plugin styles
 */
.ce-header {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.ce-header p,
.ce-header div {
  padding: 0 !important;
  margin: 0 !important;
}

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {}

.ce-header[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}

.ce-header[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}

.ce-header[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}

.cdx-marker {
  background: rgba(245, 235, 111, 0.29);
  padding: 3px 0;
}

.cdx-underline {
  text-decoration: underline;
}

.ce-rawtool__textarea {
  min-height: 200px;
  resize: vertical;
  border-radius: 5px;
  background-color: #1e2128;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #9ca3b3;
}

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

.ce-delimiter {
  line-height: 1.6em;
  width: 100%;
  text-align: center;
}

.ce-delimiter:before {
  display: inline-block;
  content: "***";
  font-size: 30px;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}

.cdx-alert {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cdx-alert__message {
  outline: none;
}

.cdx-alert [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-alert [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-alert [contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-alert-primary {
  background-color: #ebf8ff;
  border: 1px solid #4299e1;
  color: #2b6cb0;
}

.cdx-alert-secondary {
  background-color: #f7fafc;
  border: 1px solid #cbd5e0;
  color: #222731;
}

.cdx-alert-info {
  background-color: #e6fdff;
  border: 1px solid #4cd4ce;
  color: #00727c;
}

.cdx-alert-success {
  background-color: #f0fff4;
  border: 1px solid #68d391;
  color: #2f855a;
}

.cdx-alert-warning {
  background-color: #fffaf0;
  border: 1px solid #ed8936;
  color: #c05621;
}

.cdx-alert-danger {
  background-color: #fff5f5;
  border: 1px solid #fc8181;
  color: #c53030;
}

.cdx-alert-light {
  background-color: #fff;
  border: 1px solid #edf2f7;
  color: #1a202c;
}

.cdx-alert-dark {
  background-color: #2d3748;
  border: 1px solid #1a202c;
  color: #d3d3d3;
}

.cdx-alert.cdx-settings-button {
  margin: 3px;
  width: 28px;
  height: 28px;
  opacity: 0.5;
}

.cdx-alert.cdx-settings-button:hover {
  opacity: 1;
}

.cdx-alert.cdx-settings-button--active,
.cdx-alert.ce-settings__button--focused {
  font-weight: bold;
  opacity: 0.9;
}

.cdx-attaches--with-file {
  display: flex;
  padding: 13px 20px;
  border: 1px solid #e6e9eb;
  border-radius: 3px;
  background: #fff;
}

.cdx-attaches--with-file .cdx-attaches__file-info {
  flex-grow: 8;
  max-width: calc(100% - 80px);
}

.cdx-attaches--with-file .cdx-attaches__download-button {
  display: flex;
  align-items: center;
}

.cdx-attaches--with-file .cdx-attaches__download-button svg {
  fill: #7b7e89;
}

.cdx-attaches--with-file .cdx-attaches__file-icon {
  margin-right: 20px;
  position: relative;
}

.cdx-attaches--with-file .cdx-attaches__file-icon::before {
  position: absolute;
  bottom: 7px;
  left: 8.5px;
  font-size: 8px;
  font-weight: 900;
  text-transform: uppercase;
  background: #fff;
  line-height: 150%;
  content: attr(data-extension);
}

.cdx-attaches--with-file .cdx-attaches__file-icon svg {
  fill: currentColor;
}

.cdx-attaches--with-file .cdx-attaches__size {
  color: #7b7e89;
}

.cdx-attaches--with-file .cdx-attaches__size::after {
  content: attr(data-size);
  margin-left: 0.2em;
}

.cdx-attaches--with-file .cdx-attaches__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  max-width: 90%;
}

.cdx-attaches--loading .cdx-attaches__title,
.cdx-attaches--loading .cdx-attaches__file-icon,
.cdx-attaches--loading .cdx-attaches__size,
.cdx-attaches--loading .cdx-attaches__download-button,
.cdx-attaches--loading .cdx-attaches__button {
  opacity: 0;
  font-size: 0;
}

.cdx-attaches__button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdx-attaches__button svg {
  height: 14px;
  margin-top: 0;
  margin-right: 8px;
}

.cdx-attaches__button:hover {
  color: #393f52;
}

/* END CE CSS*/

.btn-sol:hover {
  color: #fff;
  background-color: #111;
}

.btn-sol:active {
  color: #fff;
  background-color: #6f75b9;
  border-color: #6f75b9;
  border-radius: none !important;
}

.btn-sol:focus {
  border-radius: 0 !important;
  outline: none !important;
}

.btn-sol {
  display: inline-block;
  text-decoration: none;
  transition: all 0.16s ease 0s;
  border-style: solid;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
  border-color: rgb(17, 17, 17);
  border-width: 2px;
  border-radius: 0px;
  padding: 10px 22px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 3px;
}

.btn-sol.btn-sol-secondary {
  font-size: 10px;
  letter-spacing: 5px;
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}

.btn-sol.btn-sol-secondary:hover {
  background-color: #fff;
  border-color: #111;
  color: #111;
}

ul.checkmark li {
  list-style: none;
  line-height: 1;
  margin-top: 2rem;
  padding-left: 1.5em;
  text-indent: -1.5em;

}

ul.checkmark li::before {
  content: "\f00c";
  color: var(--success);
  padding-right: 10px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
}


.pill {
  box-shadow: 0px 4px 20px -5px rgb(0 0 0 / 20%);
  margin-top: 1rem;
  border-radius: 10px;
  width: fit-content;
  padding: 0.5em 2em;
}

.pill.container {
  width: 100% !important;
}

.pill-big {
  padding: 1.2rem 2rem;
}

button.pill {
  padding: 7px 25px;
  font-size: 1em;
  line-height: 2em;
  border-radius: 5px;
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-transition: all 0.16s ease;
  transition: all 0.16s ease;
  user-select: none;
  background-color: transparent;
  color: #2c2c2c;
}

.pill.pill-nb {
  box-shadow: none;
}

.pill.reverse {
  background-color: #6f75b9;
  color: #fff;
}

.pill-sticky-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 1050;
  top: 0;
}

.pill.pill-sticky-top {
  position: sticky;
  top: 0;
  z-index: 1050;
  text-align: center;
  font-size: 12px;
}

@media (max-width:889px) {
  .pill.pill-sticky-top {
    margin-top: 0;
    border-radius: 0 0 10px 10px;
  }
}


.pill-lg {
  padding: 1.2rem 2rem;
}

.pill:not(input, .reverse, .pill-big):active,
.pill:not(input, .reverse, .pill-big):hover {
  color: #fff !important;
  background-color: #6f75b9 !important;
}

.pill.reverse:active,
.pill.reverse:hover {
  color: #111 !important;
  background-color: transparent !important;
}

.pill.pill-active {
  box-shadow: 0 0 0 18px transparent;
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
}



.pill:not(.pill-big):active .mdi,
.pill:not(.pill-big):active .fas,
.pill:not(.pill-big):hover .mdi,
.pill:not(.pill-big):hover .fas {
  color: #fff !important;
}

.cookie-container {
  height: 0px;
  transition: all .6s ease;
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: #fff;
  border-radius: 15px;
  z-index: 1032;
  max-width: 750px;
  box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 20%);
  overflow: hidden;
  opacity: 0;
  display: none;
}

.pulse {
  box-shadow: 0 0 0 18px transparent;
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
}

@-webkit-keyframes pulse {
  from {
    box-shadow: 0 0 0 0 #111;
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 #111;
  }
}

.cookie-container.show {
  min-height: 400px;
  height: 400px;
  bottom: 25px;
  right: 25px;
  opacity: 1;
  display: block;
}

.cookie-container .cookie-body {
  font-size: 1.07rem;
}

.cookie-container .cookie {
  position: absolute;
  right: -75px;
  top: -70px;
  width: 325px;
  height: auto;
  filter: url(#dropshadow);
}

.cookie-container .cookie-body {
  font-weight: 600;
  font-size: 4rem;
  position: relative;
}

.cookie-container .cookie-body h3,
.cookie-container .cookie-body p,
.cookie-container .cookie-body li {
  background-color: #fff;
  width: fit-content;
}

.cookie-container .cookie-footer {
  font-weight: 600;
  font-size: 1rem;
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
}

#search {
  width: 50%;
}

@media (max-width:768px) {

  ul.checkmark li {
    list-style: none;
    line-height: 1;
    margin-top: .5rem;
    padding-left: 1.5em;
    text-indent: -1.5em;
    padding: 10px 10px;
  }

  .cookie-container.show {
    bottom: 0;

    right: 0;
  }
}

@media (max-width:514px) {
  .cookie-container.show {
    min-height: 60vh;
    height: 60vh;
  }

  .cookie-container .cookie {
    right: -75px;
    bottom: -70px;
    top: initial;
  }

  .cookie-container p,
  .cookie-container li {
    font-size: 0.9rem;
  }

  #search {
    width: 100% !important;
  }
}

@media (max-width:414px) {
  .cookie-container {
    min-height: 50vh;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0;
    z-index: 1032;
    max-width: 100vw;
    box-shadow: none;
    overflow: hidden;
  }
}

@media (min-width:997px) {
  .cookie-container .cookie-body {
    font-size: 6rem;
  }
}

.dropdown-menu {
  z-index: 1033 !important;
}